import StyledLink from 'components/StyledLink'
import UserContext from 'context/UserContext'
import React, { useContext } from 'react'

const LinksPsychologist = () => {
  const [user] = useContext(UserContext)
  return (
    <>
      <StyledLink underlineColor={'var(--turquoise)'} to='/psykolog/profil'>
        Profil
      </StyledLink>
      {/* <StyledLink to='/psykolog/dokument'>Dokument</StyledLink> */}
      {/* <StyledLink to='https://www.google.com/' grey={!user.validated}>Journal</StyledLink> */}
      {user.validated && (
        <StyledLink underlineColor={'var(--turquoise)'} to='psykolog/fraga-psykologen'>
          Frågor
        </StyledLink>
      )}
      {user.validated && (
        <StyledLink
          underlineColor={'var(--turquoise)'}
          to='/bokningar'
          grey={!user.validated}>
          Bokningar
        </StyledLink>
      )}
      {user.validated && (
        <StyledLink
          underlineColor={'var(--turquoise)'}
          to='/schemalaggning'
          grey={!user.validated}>
          Schema
        </StyledLink>
      )}
      {user.validated && (
        <StyledLink
          underlineColor={'var(--turquoise)'}
          to={user.validated ? '/fakturera' : '#'}
          grey={!user.validated}>
          Faktura
        </StyledLink>
      )}
    </>
  )
}
export default LinksPsychologist
