import { answerQuestion, getOneQuestion, reserveQuestion } from 'api/apiQuestion'
import DotsSpinner from 'components/DotsSpinner'
import { QueryKeys } from 'config'
import UserContext from 'context/UserContext'
import { useContext, useState } from 'react'
import { AiOutlineLink } from 'react-icons/ai'
import { useMutation, useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { userFeedback } from 'util/utilUi'
import ReservedBar from './ReservedBar'
import { Answer, Grid, Question, QuestionIcon, TextField } from './components'
import { getDateString } from 'util/utils'

const LinkRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`
const TrucateLink = styled.a`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function AnsweringClientsPage() {
  const userState = useContext(UserContext)
  const [user] = userState
  const [firstText, setFirstText] = useState('')
  const [secondText, setSecondText] = useState('')
  const [links, setLinks] = useState('')
  const { questionId } = useParams()
  const { isLoading: isReserving, mutateAsync } = useMutation(shouldReserve =>
    reserveQuestion(question.id, shouldReserve, userState)
  )
  const {
    data: question,
    refetch,
    isFetching
  } = useQuery(QueryKeys.oneQuestion, () => getOneQuestion(questionId, userState))

  const submitAnswer = async () => {
    const answer = `${firstText}\n${secondText}`
    try {
      await answerQuestion(question.id, answer, links, userState)
      userFeedback(true, 'Fråga besvarad!')
      window.location.reload()
    } catch (err) {
      userFeedback(false, '', err?.error?.msg || 'Något gick fel')
      console.log(err)
    }
  }
  const reserveClick = async shouldReserve => {
    await mutateAsync(shouldReserve)
    refetch()
  }
  const reservedByMe = question?.answerer && question.answerer.id === user.id
  console.log(question)

  if (question && !question.asker) {
    return (
      <div className='darkText'>
        <div className='pageTitleBar'>Fråga Psykologen</div>
        <div className='page noPaddingMobile'>
          <p className='darkText'>Fel: Fråga hade ingen patient</p>
        </div>
      </div>
    )
  }
  return (
    <div className='darkText psychologistPageColors'>
      <div className='pageTitleBar'>Fråga Psykologen</div>
      <div className='page noPaddingMobile'>
        <div className='flex scaleOnHover clickable'>
          <h2>
            <Link to='/psykolog/fraga-psykologen'>&lt; Alla frågor</Link>
          </h2>
        </div>
        {!question ? (
          <DotsSpinner />
        ) : (
          <Grid>
            {!question.answer.text && (
              <ReservedBar
                question={question}
                reserveClick={reserveClick}
                disableReserve={isReserving || isFetching}
              />
            )}
            <Question>
              <div>
                <h2 style={{ marginBottom: '2px', marginTop: 6 }}>{question.title}</h2>
                <h4 className='mt-0 mb-0' style={{ marginTop: 7 }}>
                  {question.asker.firstName} {question.asker.lastName}{' '}
                </h4>
                <p className='mt-1'>{question.question}</p>
              </div>
              <p className='mt-1 mb-0'>{getDateString(question.createdAt)}</p>
            </Question>

            <QuestionIcon>{/* <BsQuestion size={100} /> */}</QuestionIcon>
            {reservedByMe && !question.answer.text && (
              <>
                {/* <AnswerIcon>
                    <BsFillChatTextFill size={70} style={{ padding: '15px', boxSizing: 'content-box' }} />
                  </AnswerIcon> */}
                <Answer>
                  <br />
                  <br />
                  <h4>Bekräfta/validera det användaren skrivit</h4>
                  <TextField
                    value={firstText}
                    onChange={setFirstText}
                    backgroundColor={'white'}
                  />
                  <h4>Svara på frågan och hänvisa till erfarenhet och forskning</h4>
                  <TextField
                    value={secondText}
                    onChange={setSecondText}
                    backgroundColor={'white'}
                  />
                  <h4>Klistra in eventuella länkar separerade med kommatecken</h4>
                  <TextField
                    value={links}
                    onChange={setLinks}
                    backgroundColor={'white'}
                  />
                  {!question.answer.text && (
                    <button onClick={submitAnswer}>Skicka</button>
                  )}
                </Answer>
              </>
            )}
            {question.answer.text && (
              <Answer>
                <h3 className='mb-1 mt-2'>Svar:</h3>
                <TextField
                  value={question.answer.text}
                  readOnly
                  backgroundColor={'white'}
                />

                {question.answer.links.length > 0 && (
                  <>
                    <h3>Fördjupningsmaterial</h3>
                    {question.answer.links.map(link => (
                      <LinkRow>
                        <AiOutlineLink size={20} style={{ marginRight: 5 }} />
                        <TrucateLink className='underline' href={link}>
                          {link}
                        </TrucateLink>
                      </LinkRow>
                    ))}
                  </>
                )}
              </Answer>
            )}

            {!question.answerer && (
              <h2 style={{ gridColumn: '2/3', textAlign: 'center' }}>
                Reservera frågan för att svara
              </h2>
            )}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default AnsweringClientsPage
