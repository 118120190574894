import React, { useState } from 'react'
import AuthRoute from 'components/AuthRoute'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import AdminRoutes from './AdminRoutes'
import PsychologistRoutes from './PsychologistRoutes'
import Spinner from 'components/Spinner'
import styled from 'styled-components'

const AskPsychologistIntroPage = React.lazy(() =>
  import('pages/AskPsychologistIntroPage')
)
const AskPsychologistPage = React.lazy(() => import('pages/AskPsychologistPage'))
const BookingsPage = React.lazy(() => import('pages/BookingsPage'))
const CouponsClient = React.lazy(() => import('pages/CouponsClient'))
const CuponPaySuccessPage = React.lazy(() => import('pages/CuponPaySuccessPage'))
const HomePage = React.lazy(() => import('pages/HomePage'))
const IntegrityPolicyPage = React.lazy(() => import('pages/IntegrityPolicyPage'))
const InvoicePage = React.lazy(() => import('pages/InvoicePage'))
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'))
const PayForQuestionPage = React.lazy(() => import('pages/PayForQuestionPage'))
const PaymentPage = React.lazy(() => import('pages/PaymentPage'))
const PaymentSuccessPage = React.lazy(() => import('pages/PaymentSuccessPage'))
const PayQuestionSuccessPage = React.lazy(() => import('pages/PayQuestionSuccessPage'))
const PriceListPage = React.lazy(() => import('pages/PriceListPage'))
const ProfileAgePage = React.lazy(() => import('pages/ProfileAgePage'))
const ProfileCityPage = React.lazy(() => import('pages/ProfileCityPage'))
const ProfileClientPage = React.lazy(() => import('pages/ProfileClientPage'))
const ProfileContactInfoPage = React.lazy(() => import('pages/ProfileContactInfoPage'))
const ProfileExpertisePage = React.lazy(() => import('pages/ProfileExpertisePage'))
const ProfileExpertisePage2 = React.lazy(() => import('pages/ProfileExpertisePage2'))
const ProfileImagePage = React.lazy(() => import('pages/ProfileImagePage'))
const ProfileLanguagePage = React.lazy(() => import('pages/ProfileLanguagePage'))
const ProfileLicencePage = React.lazy(() => import('pages/ProfileLicencePage'))
const ProfileSelfDescriptionPage = React.lazy(() =>
  import('pages/ProfileSelfDescriptionPage')
)
const RegisterPage = React.lazy(() => import('pages/RegisterPage'))
const SchedulePage = React.lazy(() => import('pages/SchedulePage'))
const SearchForPsychologistPage = React.lazy(() =>
  import('pages/SearchForPsychologistPage')
)
const SelectAdvicePage = React.lazy(() => import('pages/SelectAdvicePage'))
const SelectAgePage = React.lazy(() => import('pages/SelectAgePage'))
const SelectClientTypePage = React.lazy(() => import('pages/SelectClientType'))
const SelectHelpTypePage = React.lazy(() => import('pages/SelectHelpTypePage'))
const SelectInvestigationPage = React.lazy(() => import('pages/SelectInvestigationPage'))
const SelectLanguagesPage = React.lazy(() => import('pages/SelectLanguagesPage'))
const SelectMediumPage = React.lazy(() => import('pages/SelectMediumPage'))
const SelectMediumPageBD = React.lazy(() => import('pages/SelectMediumPageBD'))
const SelectMediumPageVP = React.lazy(() => import('pages/SelectMediumPageVP'))
const SelectTimePage = React.lazy(() => import('pages/SelectTimePage'))
const SelectTherapyCategoryPage = React.lazy(() =>
  import('pages/SelectTherapyCategoryPage')
)
const SelectTherapyOption = React.lazy(() => import('pages/SelectTherapyOption'))
const SelectTimePageBD = React.lazy(() => import('pages/SelectTimePageBD'))
const SelectTimePageVP = React.lazy(() => import('pages/SelectTimePageVP'))
const SubmitInvestigationHelpPage = React.lazy(() =>
  import('pages/SubmitInvestigationHelpPage')
)
const UserTermsPage = React.lazy(() => import('pages/UserTermsPage'))
const VideoMeetingClientPage = React.lazy(() => import('pages/VideoMeetingClientPage'))
const WelcomePage = React.lazy(() => import('pages/WelcomePage/index.js'))

const PageSpinner = styled.div`
  width: 100%;
  min-height: 89vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

function AppRoutes() {
  return (
    <Suspense
      fallback={
        <PageSpinner>
          <Spinner></Spinner>
        </PageSpinner>
      }>
      <Routes>
        <Route index element={<WelcomePage />} />
        {/* onStartMeeting || */}
        <Route path='/hem' element={<AuthRoute element={<HomePage />} />} />
        <Route
          path='/bokningar'
          element={<BookingsPage onStartMeeting={function () {}} />}
        />
        <Route path='/admin/*' element={<AuthRoute admin element={<AdminRoutes />} />} />
        <Route
          path='/psykolog/*'
          element={<AuthRoute pshychologist element={<PsychologistRoutes />} />}
        />

        <Route path='/psykolog-profil1' element={<ProfileContactInfoPage />} />
        <Route path='/psykolog-profil2' element={<ProfileAgePage />} />
        <Route path='/psykolog-profil2_5' element={<ProfileExpertisePage />} />
        <Route path='/psykolog-profil3' element={<ProfileExpertisePage2 />} />
        <Route
          path='/psykolog-profil3_5'
          element={<ProfileLanguagePage nextNav='/psykolog-profil4' />}
        />
        <Route path='/psykolog-profil4' element={<ProfileSelfDescriptionPage />} />
        <Route path='/psykolog-profil5' element={<ProfileImagePage />} />
        <Route path='/psykolog-profil6' element={<ProfileCityPage />} />
        <Route path='/psykolog-profil7' element={<ProfileLicencePage />} />

        <Route path='/prislista' element={<PriceListPage />} />
        <Route path='/kuponger' element={<AuthRoute element={<CouponsClient />} />} />
        <Route
          path='/kuponger/betald'
          element={<AuthRoute element={<CuponPaySuccessPage />} />}
        />
        <Route path='/profil' element={<AuthRoute element={<ProfileClientPage />} />} />

        <Route
          path='/profilSprak'
          element={<AuthRoute element={<ProfileLanguagePage nextNav='/profil' />} />}
        />
        <Route path='/schemalaggning' element={<SchedulePage />} />
        <Route path='/registrera' element={<RegisterPage />} />
        <Route path='/videosamtal/:bookingId' element={<VideoMeetingClientPage />} />
        <Route path='/videosamtal/test' element={<VideoMeetingClientPage test />} />
        <Route path='/fakturera' element={<InvoicePage />} />

        <Route
          path='sok-psykolog'
          element={<AuthRoute element={<SearchForPsychologistPage />} />}
        />

        {/* VP Välj psykolog flow */}
        <Route
          path='/stegMedium-vp/:psychologistId'
          element={<AuthRoute element={<SelectMediumPageVP />} />}
        />
        <Route
          path='/stegTid-vp/:psychologistId'
          element={<AuthRoute element={<SelectTimePageVP />} />}
        />

        {/* Boka Direkt flow */}
        <Route
          path='/steg-alder-bd'
          element={<AuthRoute element={<SelectAgePage nextNav='/stegMedium-bd' />} />}
        />
        <Route
          path='/stegMedium-bd'
          element={<AuthRoute element={<SelectMediumPageBD />} />}
        />
        <Route
          path='/stegTid-bd'
          element={<AuthRoute element={<SelectTimePageBD />} />}
        />

        {/* Välj tjänst flow  */}
        <Route path='/steg1' element={<AuthRoute element={<SelectClientTypePage />} />} />
        <Route path='/stegAlder' element={<AuthRoute element={<SelectAgePage />} />} />
        <Route
          path='/stegHjalptyp'
          element={<AuthRoute element={<SelectHelpTypePage />} />}
        />
        <Route
          path='/stegTerapi'
          element={<AuthRoute element={<SelectTherapyCategoryPage />} />}
        />
        <Route
          path='/stegUtredning'
          element={<AuthRoute element={<SelectInvestigationPage />} />}
        />
        <Route
          path='/final-utredning'
          element={<AuthRoute element={<SubmitInvestigationHelpPage />} />}
        />
        <Route
          path='/stegRadgivning'
          element={<AuthRoute element={<SelectAdvicePage />} />}
        />

        <Route
          path='/stegTerapi/val'
          element={<AuthRoute element={<SelectTherapyOption />} />}
        />
        <Route
          path='/stegSprak'
          element={<AuthRoute element={<SelectLanguagesPage />} />}
        />
        <Route
          path='/stegMedium'
          element={<AuthRoute element={<SelectMediumPage />} />}
        />

        {/* <Route path='/stegDatum' element={<AuthRoute element={<SelectDatePage />} />} /> */}
        <Route
          path='/stegPsykolog'
          element={<AuthRoute element={<SelectTimePage />} />}
        />

        {/* Payment */}
        <Route path='/betalning/*' element={<PaymentPage />} />
        <Route
          path='/betald-order/:bookingId'
          element={<AuthRoute element={<PaymentSuccessPage />} />}
        />

        {/* Ask a psychologist */}
        <Route
          path='fraga-psykologen/betalad-fraga/:questionId'
          element={<AuthRoute element={<PayQuestionSuccessPage />} />}
        />
        <Route
          path='/fraga-psykologen/betala/:questionId'
          element={<AuthRoute element={<PayForQuestionPage />} />}
        />
        <Route
          path='fraga-psykologen-intro'
          element={<AuthRoute element={<AskPsychologistIntroPage />} />}
        />
        <Route
          path='fraga-psykologen/*'
          element={<AuthRoute element={<AskPsychologistPage />} />}
        />

        <Route path='/anvandarvillkor' element={<UserTermsPage />} />
        <Route path='/integritet' element={<IntegrityPolicyPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
