import React from 'react'
import styled from 'styled-components'
import {
  getLocalStorage
} from '../util/dataHandling'
import { Link } from 'react-router-dom'
import { getServerURL } from '../util/utils'

const Button = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  border: 3px solid;
  border-radius: 15px;
  border-color: var(--primary);
  padding: ${props => props.showClientInfo ? 0 : '15px'};
  margin: 10px 15px 10px 0;
  transition: 200ms;
  cursor: ${props => props.selectable ? 'pointer' : 'auto'};
  user-select: none;
  align-items: stretch;
  opacity: 1;

  p {
    color: ${props => props.selected ? '#fff' : '#000'};
  }

  @media screen and (min-width: 800px) {
    min-width: 400px;
  }

  @media screen and (max-width: 800px) {
    padding: 15px 0;
    width: 90%;
    margin-right: 25px;
  }
`

const RebookLink = styled.div`
  transition: 200ms;
  margin-left: auto;

  a {
    color: #${props => props.selected ? 'fff' : '000'};
    text-decoration: none;
  }

  @media screen and (max-width: 500px) {
    
    a {
      font-size: 12px;
    }
  }
`

const TextElement = styled.h1`
  margin: 4px 0;
`

// const TopText = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;


//   @media screen and (max-width: 500px) {
//     flex-direction: column-reverse;
//   }
// `

const TextContainer = styled.div`
  transition: 200ms;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  margin: 0 8px 0 8px;
  width: 100%;

  @media screen and (min-width: 500px) {
    min-width: 500px;
  }
`

function DocumentScrollComponent ({ colorOverride = false, selected, document, showClientInfo = false }) {
  const color = '#' + (colorOverride || (getLocalStorage().specialization ? getLocalStorage().specialization.color : '009192'))
  const fillColor = selected ? color : 'transparent'

  const buttonStyle = {
    backgroundColor: fillColor,
    borderColor: selected ? color : null // Else set with styled
  }

  return (
    <Button selected={selected} className='advancedScrollComponent' showClientInfo={showClientInfo} color={color} style={buttonStyle}>
      <TextContainer>
          <RebookLink selected={selected} onClick={() => window.open(getServerURL() + 'documents/' + document.filename, '_blank')}><Link to='#'>Se dokument</Link></RebookLink>
          <TextElement><b>{document.displayName}</b></TextElement>
      </TextContainer>
    </Button>
  )
}

export default DocumentScrollComponent
