import { adminAddCoupon, adminGetCoupons } from 'api/apiAdmin'
import { useEffect, useState } from 'react'
import { Content, List } from './components'
import CouponItem from './CouponItem'
import texts from 'db/couponsAdmin.json'

function CouponsAdminPage() {
  const [coupons, setCoupons] = useState([])

  const add = async () => {
    await adminAddCoupon(-1)
    refreshCoupons()
  }

  useEffect(() => {
    refreshCoupons()
  }, [])

  const refreshCoupons = async () => {
    const coupons = await adminGetCoupons()
    setCoupons(coupons)
  }

  return (
    <div className='page darkText'>
      <Content className='columnFlex'>
        <h1>Kuponger</h1>
        <p>{texts.description}</p>
        <List>
          {
            coupons.map((coupon) =>
            <CouponItem key={coupon.code} coupon={coupon} />)
          }
        </List>
        <button onClick={() => add()}>Skapa Ny</button>
      </Content>
    </div>
  )
}
export default CouponsAdminPage
