import { IoTriangle } from 'react-icons/io5'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  background-color: var(--primary3);
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  max-width: 700px;
`
export const ListItem = styled.p`
  display: inline-block;
  font-size: 0.9rem;
  padding: 0.3rem 0.5rem;
  margin: 0.5rem 0.8rem 0 0;
  border-radius: 0.8rem;
`
export const CompetenceItem = styled(ListItem)`
  background-color: white;
  box-shadow: 0 0 4px 0px lightgray;
`
export const LangItem = styled(ListItem)`
  background-color: var(--primary2);
  min-width: 90px;
  text-align: center;
`
export const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
`
export const Name = styled.h3`
  margin-bottom: 2px;
`
export const ProfileImgContainer = styled.div`
  position: relative;
  align-self: center;
`
export const FavMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 5px;
  bottom: 5px;
`
export const ProfileImg = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
`
export const MoreInfo = styled.div`
  box-sizing: content-box;

  ${props => (!props.expanded ? 'max-height: 0;' : 'max-height: 100vh;')}
  padding: 0rem 1rem 0rem 1rem;
  ${props => props.expanded && 'padding-bottom: 1rem;'}
  overflow: hidden;
  transition: max-height 250ms, padding-bottom 250ms;
  box-sizing: border-box;
`
export const IconContainer = styled.span`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
`
export const Arrow = styled(IoTriangle)`
  transform: rotate(${props => (props.flip ? '180deg' : '90deg')});
`
export const BookBar = styled.div`
  margin-top: 1rem;
  display: flex;
  button {
    flex-grow: 1;
  }
`
