import React from 'react'
import { AlertContainer } from 'react-custom-alert'
import './CustomAlert.css'

function CustomAlert() {
  return (
    <AlertContainer floatingTime={8000} />
  )
}

export default CustomAlert