import styled from "styled-components"

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  justify-content: flex-start;
  min-height: 50vh;
`

export const List = styled.div`
  margin-top: 1rem;
  min-height: 50vh;
`

export const Coupon = styled.div`
  width: 300px;
  padding: 0.7rem 1rem;
  border-bottom: 1px solid black;
  position: relative;
`

export const CopiedContainer = styled.div`
  z-index: 100;
  overflow: hidden;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  

  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    70% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
    }
  }

  >p {
    background-color: var(--primary2); 
    color: var(--darkText);
    padding: 4px;
    border-radius: 4px;
    animation-duration: 1000ms;
    animation-name: fade;
    margin-left: 40px;
  }
`