import React from 'react'
import styled from 'styled-components'
import {
  getLocalStorage
} from '../util/dataHandling'
import { useLocation } from 'react-router-dom'

const Button = styled.div`
width: 100%;
flex-shrink: 0;
border: var(--borderWidth) solid;
border-radius: 15px;
border-color: var(--primary);
background-color: ${props => props.selected ? 'var(--primary)' : null};
padding: 0 30px;
margin: 10px 0;
transition: 200ms;
cursor: pointer;
user-select: none;

@media screen and (max-width: 800px) {
  padding: 0 10px;
}

:hover {
  border-color: ${props => '#' + props.color}
`

const Title = styled.h1`
  color: ${props => props.selected ? '#fff' :  'var(--darkText)'};
  font-size: 1.75em;
`

function SimpleScrollComponent ({ selected, title, onClick, colorOverride = false }) {
  const color = '#' + (colorOverride || (getLocalStorage().specialization && getLocalStorage().specialization.color) || '009192')
  const location = useLocation()
  const fillColor = selected || location.pathname === '/stegAlder' ? color : 'transparent'

  const buttonStyle = {
    //borderColor: selected ? color : null // Else set with styled
  }

  return (
    <Button Acolor={color} style={buttonStyle} selected={selected} onClick={() => onClick()}>
      <Title selected={selected}>{title}</Title>
    </Button>
  )
}

export default SimpleScrollComponent
