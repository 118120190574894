import React from 'react'
import styled from 'styled-components'

const CookieConsentContainer = styled.div`
  background: white;
  color: var(--secondary);
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  position: fixed;
  max-width: 400px;
  bottom: 2em;
  right: 1rem;
  left: 1rem;
  line-height: 1.4em;
  padding: 1rem;
  
  @media (min-width: 27em) {
    left: auto;
    width: 100%;
  }
`

const CookieText = styled.span`
  color: var(--secondary);
`

const CookieActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`


const CookieButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: var(--secondary);
  border: 0;
  border-radius: 4px;
  box-shadow: 0;
  font-size: 1rem;
  font-weight: 700;
  padding: 0;
  padding: 0.25rem 1.5rem;
`

const CookieConsent = () => {
  const [loading, setIsLoading] = React.useState(true)
  const [isVisible, setIsVisible] = React.useState(true)

  React.useEffect(() => {
    let componentMounted = true

    function acceptCookies() {
      const cookieValue = localStorage.getItem('cookie-consent')

      if (componentMounted) {
        if (cookieValue && cookieValue === 'true') {
          setIsVisible(false)
        } else {
          setIsVisible(true)
        }
      }
      setIsLoading(false)
    }
    acceptCookies()

    return () => {
      componentMounted = false
    }
  }, [])

  function onAcceptCookies() {
    localStorage.setItem('cookie-consent', 'true')
    setIsVisible(false)
  }

  return !loading && isVisible === true ? (
    <CookieConsentContainer>
      <CookieText>Vi använder cookies för att göra webbplatsen funktionell och förbättra din upplevelse. Vi använder inte cookies för analys.</CookieText>
      <CookieActions>
        <CookieButton onClick={onAcceptCookies} type="button">Acceptera</CookieButton>
      </CookieActions>
    </CookieConsentContainer>
  ) : null
}

export default CookieConsent
