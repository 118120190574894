import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  margin-right: 1.3rem;
  vertical-align: middle;
  color: var(--darkText);
  font-size: 20px;
  cursor: pointer;

  transition: 200ms;
`
const StyledLink = ({ to = '', children, onClick, underlineColor }) => {
  const activeStyle = {
    borderBottom: `2px solid ${underlineColor || 'var(--primary)'}`
  }
  return (
    <Container>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : {})}
        onClick={onClick}
        to={to}>
        {children}
      </NavLink>
    </Container>
  )
}

export default StyledLink
