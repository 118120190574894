import styled from 'styled-components'

export const Grid = styled.div`
  margin: 2rem 0;
  grid-template-columns: 150px auto;
  width: 100%;
  grid-row-gap: 1rem;
  display: grid;
`
export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const C1 = styled.span`
  grid-column: 1/2;
`
export const C2 = styled.span`
  grid-column: 2/3;
`
export const Desc = styled.p`
  width: 100%;
`
const Round = styled.p`
  display: inline-block;
  width: max-content;
  border-radius: 2rem;
  padding: 0.2rem 0.6rem;
  margin: 0 5px 5px 0;
`
export const Competence = styled(Round)`
  border: 1px solid var(--darkText);
  background-color: white;
`
export const Lang = styled(Round)`
  border: 1px solid var(--darkText);
  background-color: white;
`
