import { generateEmptyUserData } from '../util/utils'
import { queryPurchasedBookings } from './apiBookings'
import { DateTime } from 'luxon'

const API = process.env.REACT_APP_API_URL

export class UserInputError extends Error {
  constructor(message) {
    super(message)
    this.name = 'UserInputError'
  }
}

export const alertNoConnection = () => {
  window.alert('Ingen kontakt med servern, vänligen försök senare.')
}

export const login = async (personalNumber, accountType = 'client', sessionId) => {
  try {
    const res = await request(`/login?sessionId=${sessionId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ personalNumber, accountType })
    })

    const data = await res.json()
    return data
  } catch (err) {
    return {
      error: err.message || 'Något gick fel, vänligen försök igen.'
    }
  }
}

export const logout = async userState => {
  const [user, setUser] = userState
  try {
    await requestPostJson('/logout')
  } catch (err) {
    console.error(err)
  }
  setUser(generateEmptyUserData())
}

export const profileGet = async (userState) => {
  try {
  const res = await requestGetJson('/profile/info', {}, userState)
  if(!res.ok) {
    throw new Error('Could not get profile')
  }
    const profile = await res.json() // if descriptions not set
    return profile
  } catch(err) {

    console.error('error getting profile!', err)
  }
  return false
}

export const profileUpdate = async (data, userState) => {
  const res = await requestPostJson('/profile/update', data, {}, userState)

  const body = await res.json()
  return body
}
export const profileUpdateOptimistic = async (data, userState) => {
  const [user, setUser] = userState
  setUser(prev => ({ ...prev, ...data }))
  const res = await profileUpdate(data, userState)
  return res.ok
}
export const psychologistGetBookings = async (startDate, endDate) => {
  const data = await queryPurchasedBookings(startDate, endDate)
  return data
}
export const profileUploadImage = async (formData, userState) => {
  const res = await request(
    '/profile/uploadImg',
    {
      method: 'POST',
      body: formData
    },
    userState
  )
  return res.ok
}

export const profileUploadLicense = async (file, userState) => {
  const body = new FormData()
  body.append('license', file)

  const res = await request(
    '/profile/uploadLicense',
    {
      method: 'POST',
      body
    },
    userState
  )
  if (!res.ok) {
    throw await res.json()
  }
  return await res.json()
}
export const searchForPsychologist = async (searchTerm, userState) => {
  let url
  if (searchTerm) url = `/booking/psychologists?q=${searchTerm}`
  else url = `/booking/psychologists`
  const res = await requestGetJson(url, userState)

  if (!res.ok) {
    throw 'Could not get psychologists'
  }

  return res.json()
}
export const queryAvailable = async (startDate, endDate, psychologistId) => {
  // TODO query by city if physical meeting.

  const body = {
    startDate: DateTime.now().toISO(),
    endDate: DateTime.now().plus({ month: 12 }).toISO(),
    // specializations: specializationsData.specializations, // Get all bookings filter on client side
    availableMediums: ['video', 'phone', 'physical'],
    psychologistId: psychologistId
    // taken: true
  }

  if (startDate && !endDate) {
    body.startDate = startDate.toISO()
  } else if (startDate && endDate) {
    body.startDate = startDate.toISOS()
    body.endDate = endDate.toISO()
  }

  const res = await requestPostJson('/booking/queryAvailable', body)

  try {
    return await res.json()
  } catch {
    console.error('error getting bookings!')
  }
}

export const checkForSwishSuccess = async () => {}

export const getPrices = async () => {
  const res = await request('/price')
  return res.json()
}

export const getDocuments = async userState => {
  const res = await request('/documents', userState)

  const message = 'Ett fel uppstod vid hämtandet, vänligen försök igen.'
  if (!res.ok) {
    throw new UserInputError(message)
  }
  return res.json()
}

export const startVideoMeeting = async (bookingId, userDataState) => {
  const res = await requestPostJson(
    `/video-meeting/start/${bookingId}`,
    {},
    userDataState
  )
  if (!res.ok) {
    throw new UserInputError('Videosamtalet finns ej')
  }
  const { token } = await res.json()
  return token
}
export const startVideoMeetingTest = async userDataState => {
  const res = await requestPostJson(`/video-meeting/test`, {}, userDataState)
  if (!res.ok) {
    throw new UserInputError('Videosamtalet finns ej')
  }
  const { token } = await res.json()
  return token
}
export const getBankIDSession = async personalNumber => {
  const res = await request('/login/mobile/bankId', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ personalNumber })
  })
  if (!res.ok) throw new Error('BankID Kund inte startas')
  const json = await res.json()

  return json
}
export const sendInvestigation = async (text, helpOption) => {
  const res = await requestPostJson('/admin', { text, helpOption })
  return res.ok
}

export const requestPostJson = async (url, body = {}, options = {}, userState) => {
  return request(
    url,
    {
      method: 'POST',
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    },
    userState
  )
}

export const requestGetJson = async (url, options, userDataState) => {
  return request(
    url,
    {
      ...options,
      headers: {
        'Content-Type': 'application/json'
      }
    },
    userDataState
  )
}

export const request = async (url, options, userState) => {
  if(userState?.cookieExpiration && new Date(userState?.cookieExpiration) < new Date()) {
    window.location.pathname = '/' //Cookie is expired, redirect
    return
  }

  const res = await fetch(`${API}${url}`, {
    ...options,
    credentials: 'include'
  })
  if (res.status === 401) {
    // unauthorized
    window.location.pathname = '/'
    return
  }
  return res
}
