import {
  generateEmptyUserData
} from './utils'

const setLocalStorage = (data) => {
  //! only store the data we need
  localStorage.setItem('user', JSON.stringify({
    id: data.id,
    loggedIn: data.loggedIn,
    userType: data.userType,
    validated: data.validated,
    specialization: data.specialization,
    helpType: data.helpType,
    age: data.age,
    helpOption: data.helpOption,
    medium: data.medium,
    filteredEvents: data.filteredEvents,
    selectedBooking: data.selectedBooking,
    serverBooking: data.serverBooking,
    currentlyAddingBookings: data.currentlyAddingBookings,
    shouldCancelOrder: data.shouldCancelOrder,
    selectedPsychologist: data.selectedPsychologist,
    cookieExpiration: data.cookieExpiration
  }))
}

const getLocalStorage = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      return user
    }
  } catch (error) {
    console.log(error);
  }
  return generateEmptyUserData()
}

const goToURL = (url) => {
  window.location.href = url
}

export {
  setLocalStorage,
  getLocalStorage,
  goToURL
}
