import { getAllQuestions } from 'api/apiQuestion'
import Header from 'components/Header'
import QuestionPreview from 'components/QuestionPreview'
import { QueryKeys } from 'config'
import UserContext from 'context/UserContext'
import React, { useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Content } from './components'

function QuestionsOverviewPage() {
  const userState = useContext(UserContext)
  const { data: questions } = useQuery(QueryKeys.questions, () =>
    getAllQuestions(userState)
  )
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const onQuestionClick = questionId => {
    queryClient.setQueryData(
      'oneQuestion',
      questions.find(q => q.id === questionId)
    )
    navigate(`/psykolog/fraga-psykologen/${questionId}`)
  }
  const withoutAnswer = questions?.filter(q => !q.answer.text)
  const withAnswer = questions?.filter(q => !!q.answer.text)
  return (
    <div className='psychologistPageColors'>
      <div className='pageTitleBar '>Fråga Psykologen</div>
      <div className='page heartBg darkText'>
        <Content>
          <h2>Obesvarade frågor ({withoutAnswer?.length})</h2>

          {questions &&
            withoutAnswer.map(question => (
              <QuestionPreview
                className='raiseOnHover'
                key={question.id}
                question={question}
                onClick={() => onQuestionClick(question.id)}
                showAskwer
              />
            ))}

          <h2>Besvarade frågor ({withAnswer?.length})</h2>
          {questions &&
            withAnswer.map(question => (
              <QuestionPreview
                className='raiseOnHover'
                key={question.id}
                question={question}
                onClick={() => onQuestionClick(question.id)}
                showAskwer
              />
            ))}
        </Content>
      </div>
    </div>
  )
}

export default QuestionsOverviewPage
