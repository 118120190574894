import React from 'react'
import styled from 'styled-components'
import BookingInfoClient from './BookingInfoClient/BookingInfoClient'
import AdvancedBookingInfo from '../components/AdvancedBookingInfo'
import noProfilePicture from '../img/no-profile-image.svg'
import { useLocation } from 'react-router-dom'

const Button = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  border-bottom: 1px solid black;
  padding: ${props => props.showClientInfo ? 0 : '15px'};
  margin: 10px 15px 10px 0;
  transition: 200ms;
  cursor: ${props => props.selectable ? 'pointer' : 'auto'};
  user-select: none;
  align-items: stretch;
  opacity: ${props => props.greyedOut ? '0.5' : '1'};
  
  p {
    color: ${props => props.selected ? '#fff' : '#000'};
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
  :hover {
    box-shadow: inset 0 0 100rem 0 rgba(0,0,0, 0.1);
  }
`

const ProfileImg = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 100%;
  margin-right: 20px;
  align-self: center;
`

const MeetingInfoContainer = styled.div`
  display: flex;
  width: 130px;
  justify-content: center;
  flex-direction: column;
  border-right: 2px solid #f4f4f4;
  margin-right: 20px;

  @media screen and (max-width: 500px) {
    align-items: center;
    border: none;
    width: 100%;
  }

  >p {
    margin: 2px 0;
    margin-left: 20px;
    font-size: 18px;
  }
`

function MeetingInfo ({ booking }) {
  return (
    <MeetingInfoContainer>
      {booking.medium.selected === 'video' ? <p>Videolänk</p> : ''}
      {booking.medium.selected === 'phone' ? <p>Telefon</p> : ''}
      {booking.medium.selected === 'phone' && booking.client.phone ? <p>{booking.client.phone}</p> : ''}
      {booking.medium.selected === 'physical' ? <p>Fysiskt möte</p> : ''}
      {booking.medium.selected === 'physical' && booking.psychologist.city ? <p>{booking.psychologist.city}</p> : ''}
    </MeetingInfoContainer>
  )
}

function AdvancedScrollComponent ({ colorOverride = false, selected, filter = '', booking, onClick, unresponsive = false, showClientInfo = false, bookings = [], psychologists = null, setUserData = null }) {
  const location = useLocation()

  let press
  let available = false
  if (booking) {
    if (booking.greyedOut) {
      unresponsive = true
    }
    if (booking.status === 'available' || location.pathname === '/bokningar') {
      available = true
      if (!unresponsive) {
        press = () => {
          onClick()
        }
      }
    }
  }

  const color = 'var(--primary)'//'#' + (colorOverride || (getLocalStorage().specialization ? getLocalStorage().specialization.color : 'var(--primary)'))
  const fillColor = selected ? color : 'transparent'
  const img = booking?.psychologist?.profileImg || noProfilePicture
  const greyedOut = booking && booking.greyedOut ? booking.greyedOut : false

  const buttonStyle = {
    backgroundColor: fillColor,
  }

  return (
    <Button selected={selected} className='advancedScrollComponent' showClientInfo={showClientInfo} selectable={available && !unresponsive} greyedOut={greyedOut} style={buttonStyle} onClick={press}>
      {showClientInfo ? (<MeetingInfo booking={booking} />) : (location.pathname !== '/stegTid' || (psychologists && psychologists._id) ? <ProfileImg src={img} /> : null)}
      {booking ? (
        bookings.length || (psychologists && psychologists._id)  ? (
          <AdvancedBookingInfo filter={filter} selected={selected} booking={booking} showClientInfo={showClientInfo} bookings={bookings} />
        ) : (
          <BookingInfoClient filter={filter} selected={selected} booking={booking} showClientInfo={showClientInfo} bookings={bookings} setUserData={setUserData} />
        )
      ) : ''
      }
    </Button>
  )
}

export default AdvancedScrollComponent
