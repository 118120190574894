import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import '../css/animations.css'

const animationDuration = 100

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: blur(2px);
  overflow-y: auto;
`

const PromptElement = styled.div`
  margin: 20px;
  width: 100%;
  max-width: 1000px;
  z-index: 100;
  
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  background: #fff;
  opacity: ${props => props.visible ? 1 : 0};
  cursor: default;
  transition: ${animationDuration}ms;

  >p {
    color: #000 !important;
  }
`

function Popup ({ children, visible = true, onClose, big = false }) {
  const [animationState, setAnimationState] = useState(visible)

  const bgClick = (e) => {
    e.stopPropagation()
    setAnimationState(false)

    window.setTimeout(() => {
      onClose()
    }, animationDuration)
  }

  useEffect(() => {
    setAnimationState(visible)
    document.body.classList.toggle('noScroll', visible)
  }, [visible])

  if (!visible) return null

  return (
    <Container clickable={animationState} onClick={bgClick}>
      <PromptElement onClick={(e) => e.stopPropagation()} visible={animationState} big={big}>
        {children}
      </PromptElement>
    </Container>
  )
}

export default Popup
