import { Settings } from "luxon"
Settings.defaultLocale = 'sv-SE'

export const earliestWorkHour = 6

//This one is to gather queryKeys. I don't wanna Ctrl + F around the whole project to see what keys I have
export const QueryKeys = {
  oneBooking: 'oneBooking',
  oneQuestion: 'oneQuestion',
  questions: 'questions',
  profile: 'profileGet',
  oneQuestionPay: 'oneQuestionPay',
  users: 'users',
  psychologists: 'psychologists'
}