import PsychologistFullInfo from "components/PsychologistFullInfo";
import styled from "styled-components";

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
`;

export const List = styled.div`
  width: 100%;
`;
export const FilterContainer = styled.div`
  background-color: var(--primary4);
  padding: 2rem;
`;
export const Psychologist = styled(PsychologistFullInfo)``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  max-width: 60%;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

export const SearchForPsychologistInput = styled.input`
  flex-grow: 1;
  height: 50px;

  background-color: var(--bgColor);
  border: 3px solid var(--primary);
  border-radius: 15px;
  outline: none;
  padding: 10px 40px;

  font-size: 18px;
  color: #000;
  width: 100%;

  ::-webkit-input-placeholder {
    font-size: 18px;
    color: #000;
  }
`;

export const SearchForPsychologistLabel = styled.label`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  :before {
    content: "";
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill-rule='evenodd'%3E%3Cpath d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E")
      center / contain no-repeat;
  }
`;

export const Person = styled.div`
  width: 100%;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
