import React, { useState } from 'react'
import styled from 'styled-components'
import mediums from '../db/mediums.json'
import {
  getLocalStorage
} from '../util/dataHandling'
import '../css/animations.css'

const Button = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  border: 3px solid;
  border-radius: 15px;
  border-color: var(--primary);
  padding: 15px;
  margin: 10px 15px 10px 0;
  transition: 200ms;
  cursor: ${props => props.selectable ? 'pointer' : 'auto'};
  user-select: none;
  opacity: ${props => props.greyedOut ? '0.5' : '1'};

  animation-name: ${props => props.shouldAnimate ? 'bounce' : ''};
  animation-delay: 512ms;
  animation-duration: 512ms;

  @media screen and (max-width: 800px) {
    margin: 10px 0;
    align-self: center;
  }
`

const Medium = styled.div`
  margin: 0 5px;
  font-size: 16px;
  ${props => props.highlighted ? '' : 'display: none;'}
  transition: 200ms;
`

const TopRow = styled.div`
  display: flex;

  h3 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    margin-top: 0;
    cursor: pointer;
    margin-left: 20px;
  }

  @media screen and (max-width: 800px) {
    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
`

const TimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0; 
`

const ScheduleTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  min-width: 100px;
  transition: 200ms;
  user-select: none;
  border-radius: 30px;
  border: none;
  font-size: 15px;
  color: #000;
  background-color: ${props => props.booked ? '#FF8A8A' : '#f4f4f4'};
  transition: 200ms;
  margin-right: 15px;
  margin-bottom: 10px;

  p {
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 18px;
  }

  @media screen and (max-width: 800px) {
    padding: 0;

    p {
      font-size: 16px;
    }
  }
`

const MediumContainer = styled.div`
  display: flex;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`

function ScheduleComponent ({ date, selected, bookings, setSelectedDates, selectedDates, onDeleteDayBookings }) {
  const color = getLocalStorage() ? (getLocalStorage().specialization ? '#' + getLocalStorage().specialization.color : '') : '#fff'
  const fillColor = 'transparent'
  const buttonStyle = {
    backgroundColor: fillColor,
    borderColor: selected ? color : null // Else set with styled
  }

  const handleDeleteDay = () => {
    const bookingsToBeDeleted = []
    for (const meeting of bookings) {
      bookingsToBeDeleted.push(meeting.id)
    }
    onDeleteDayBookings(bookingsToBeDeleted)
  }

  const selectedMediums = bookings[0].medium.available

  return (
    <Button shouldAnimate={bookings[0].scrollTo} id={bookings[0].scrollTo ? 'scrollTo' : ''} className='scheduleComponent' color={color} selectable={false} style={buttonStyle} onClick={null} key={bookings[0].id}>
      <TopRow>
        <h3>{date}</h3>
        <p
          style={{
            marginLeft: 'auto'
          }}
          onClick={() => {
            setSelectedDates([...selectedDates, new Date(bookings[0].startDate)])
          }}
        >
          Redigera
        </p>
        <p
          onClick={() => {
            handleDeleteDay()
          }}
        >
          Ta bort
        </p>
      </TopRow>
      <TimeContainer>
        {
          bookings.map(meeting => {
            return (
              <ScheduleTextContainer
                key={meeting.id}
                booked={meeting.status !== 'available'}
              >
                <p>
                  {
                    `${new Date(meeting.startDate).getHours() < 10 ? '0' + new Date(meeting.startDate).getHours() : new Date(meeting.startDate).getHours()}.${new Date(meeting.startDate).getMinutes() < 10 ? '0' + new Date(meeting.startDate).getMinutes() : new Date(meeting.startDate).getMinutes()}-${new Date(meeting.endDate).getHours() < 10 ? '0' + new Date(meeting.endDate).getHours() : new Date(meeting.endDate).getHours()}.${new Date(meeting.endDate).getMinutes() < 10 ? '0' + new Date(meeting.endDate).getMinutes() : new Date(meeting.endDate).getMinutes()}`
                  }
                </p>
              </ScheduleTextContainer>
              
            )
          })
        }
      </TimeContainer>
      <MediumContainer>
        {
          mediums.scrollListData.map(medium => {
              return (
                <Medium 
                  key={medium.userDataTag}
                  highlighted={selectedMediums.includes(medium.userDataTag)}
                >
                  {medium.title}
                </Medium>
              )
            }
          )
        }
      </MediumContainer>
    </Button>
  )
}

export default ScheduleComponent
