import React, { useEffect, useRef, useState } from 'react'

function DotsSpinner() {
  const [dots, setDots] = useState(1)
  const loop = useRef()
  useEffect(() => {
    loop.current = setInterval(() => {
      setDots(prev => prev+1)
    }, 500)
    return () => {
      clearInterval(loop.current)
    }
  }, [])
  return (
    <span>{''.padStart(dots%3+1, '.')}</span>
  )
}

export default DotsSpinner