import { request, requestPostJson } from "./api"

export const getAllPsychologists = async (userState) => {
  return request('/psychologist', {}, userState).then(res => res.json())
} 
export const getOnePsychologist = async (psychologistId, userState) => {
  return request(`/psychologist?psychologistId=${psychologistId}`, {}, userState).then(res => res.json())
} 
export const getAllSpecializations = async (ages, userState) => {
  return requestPostJson(`/help-option/all-specializations`, { ages }, userState).then(res => res.json())
}
export const togglePsychologistAsFavorite = async (psychologistId, setFavorite) => {
  const url = setFavorite ? `/psychologist/favorites/add` : `/psychologist/favorites/remove`
  return requestPostJson(url, { psychologistId }).then(res => res.json())
}