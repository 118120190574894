import Popup from "components/Popup";
import React from "react";
import { useNavigate } from "react-router-dom";

const UpdateProfilePopup = ({
  isOpen,
  setIsOpen,
}) => {
  const navigate = useNavigate();
  return     (
  <Popup onClose={() => setIsOpen(false)} visible={isOpen}>
    <div>
      <p>
      Vi har inte din mejladress! För att fortsätta, vänligen lägg till din mejladress under din profilinställning. Mejladressen används för att skicka bekräftelse mejla dig gällande dina ärenden.
      </p>
      <div className='flex justifyCenter'>
        <button className='ghostButton' onClick={() => setIsOpen(false)}>Senare</button>
        <button onClick={()=> navigate('/profil')}>Gå till Profil</button>
      </div>
    </div>
</Popup>
)
};

export default UpdateProfilePopup;
