import UserContext from "context/UserContext";
import data from "db/documentPsychologist.json";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { getDocuments } from "../api/api";
import ScrollList from "../components/ScrollList";
import TitleDesc from "../components/TitleDesc";

const Left = styled.div`
  flex-direction: column;

  button {
    margin-top: 50px;
  }
`;

const UploadFile = styled.input`
  border: none;
  outline: none;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const InputLabel = styled.label`
  font-size: 22px;
  display: inline-block;
  cursor: pointer;

  border-bottom: 3px solid black;
`;

function LeftComponent(data) {
  return (
    <Left>
      <TitleDesc title={data.title} desc={data.description} />
    </Left>
  );
}

const Button = styled.div`
  flex-shrink: 0;
  border: 3px solid;
  border-radius: 15px;
  border: 2px solidvar(--primary);
  padding: 0 30px;
  margin: 10px 0;
  transition: 200ms;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 800px) {
    padding: 0 10px;
  }

  :hover {
    border-color: ${(props) => "#" + props.color};
  }
`;

function DocumentsPsychologist() {
  const userState = useContext(UserContext);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDocuments(userState);
      setDocuments(res);
    };

    fetch();
  }, []);

  return (
    <div>
      <div className="pageTitleBar">Dokument</div>
      <div className="page">
        <div className="pageContent">
          <p>{data.description}</p>
          <ScrollList
            selected={false}
            onClick={() => console.log("hej")}
            objects={documents}
          />
        </div>
      </div>
    </div>
  );
}
export default DocumentsPsychologist;
