import {
  adminCreatePsychologist,
  adminDeleteUser,
  adminGetLicense,
  adminGetOneUser,
  adminUpdateUser
} from 'api/apiAdmin'
import ToggleSlider from 'components/ToggleSlider'
import UserContext from 'context/UserContext'
import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { userFeedback } from 'util/utilUi'
import '../css/fullscreen.css'

function AdminClientDetailsPage() {
  const userState = useContext(UserContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const [licenseLink, setLicenseLink] = useState('')
  const queryClient = useQueryClient()
  const { data: client, refetch } = useQuery('not specified', () => adminGetOneUser(id))

  useEffect(() => {
    adminGetLicense(id)
      .then(setLicenseLink)
      .catch(() => {
        console.log('No license')
      })
  }, [])

  const { mutate } = useMutation(profile => adminUpdateUser(id, profile, userState), {
    onMutate: async profile => {
      await queryClient.cancelMutations('todo')
      const prevProfile = queryClient.getQueryData('user')
      console.log(prevProfile)
      queryClient.setQueryData('user', old => ({ ...old, ...profile }))
      return { prevProfile }
    },
    onError: (err, profile, context) => {
      queryClient.setQueryData('user', context.prevProfile)
    },
    onSuccess: data => {
      refetch()
      queryClient.setQueryData('user', data)
    }
  })

  const deleteUser = async () => {
    if (
      window.confirm(
        'Är du säker på att du vill ta bort användaren? Denna handling kan inte ångras.'
      )
    ) {
      const success = await adminDeleteUser(id)
      userFeedback(success, 'Användare borttagen', 'Kunde inte ta bort!')
      success && navigate('/admin/alla-anvandare')
    }
  }
  const canApplyClick = on => {
    mutate({ canBecomePsychologist: on })
  }
  const makePsychologist = async () => {
    if (!client?.id) return window.alert('Inget id')
    if (!window.confirm('Vill du godkänna användare som psykolog?')) return
    try {
      await adminCreatePsychologist(client.id)
      window.alert('Användare är nu godkänt som psykolog')
      navigate('/admin/anvandare/psykolog')
    } catch (err) {
      userFeedback(false, '', err?.error?.msg || 'Något gick fel')
    }
  }

  if (!client) return null
  return (
    <div className='page darkText'>
      <div className='pageContent'>
        <h1>{client.fullName}</h1>
        <p>Email: {client.email}</p>
        <p>Telefonnummer: {client.phoneNumber}</p>
        <p>
          Språk:{' '}
          {client.languages.map(lang => (
            <span key={lang}>{lang} </span>
          ))}{' '}
        </p>
        <p>Antal gratisbesök: {client.coupons.numInfiniteCoupons}</p>
        <p>Kan söka psykolog</p>
        <ToggleSlider value={!!client.canBecomePsychologist} onChange={canApplyClick} />
        <br />
        {client.canBecomePsychologist && (
          <>
            {licenseLink ? (
              <p>
                Licens:{' '}
                <a
                  href={licenseLink}
                  download
                  target='_blank'
                  className='underline clickable'>
                  Ladda ner
                </a>
              </p>
            ) : (
              <p>Ingen license har laddats upp</p>
            )}
          </>
        )}
        <div className='flex mt-1'>
          {client && client.canBecomePsychologist && (
            <button
              className='selfCenter'
              onClick={makePsychologist}
              disabled={!client.license}>
              Godkänn psykolog
            </button>
          )}
          <button className='selfCenter'>Ge gratisbesök</button>
          <button className='selfCenter' onClick={deleteUser}>
            Ta bort
          </button>
        </div>
      </div>
    </div>
  )
}

export default AdminClientDetailsPage
