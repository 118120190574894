import { request, requestGetJson, requestPostJson, UserInputError } from './api'
import { getServerURL } from '../util/utils'

export const adminUpdateUser = async (userId, userProfile, userState) => {
  const res = await request(`/admin/user/${userId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userProfile)
  })
  return await res.json()
}

export const adminDeleteUser = async userId => {
  const data = {
    userId
  }
  const res = await requestPostJson('/admin/removeUser', data)
  return res.ok
}

export const adminCreatePsychologist = async userId => {
  const res = await requestPostJson('/profile/register-as-psychologist', { id: userId })
  if (!res.ok) throw await res.json()
  return await res.json()
}

export const adminGetLicense = async userId => {
  const res = await requestGetJson('/admin/license?userId=' + userId)
  const data = await res.json()
  if (!data.link) throw new Error('No link')
  return data.link
}

export const adminValidatePsychologist = async (userId, validity) => {
  console.log(userId, validity)
  const res = await requestPostJson('/admin/validate', { userId, validity })
  if (!res.ok) throw 'Failed validation'
}
export const adminUploadDocuments = async formData => {
  const res = await fetch('/admin/documents', {
    method: 'POST',
    header: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': getServerURL()
    },
    body: formData,
    credentials: 'include'
  })
  return res.status === 200
}
export const adminAddCoupon = async value => {
  const data = {
    value
  }
  const res = await requestPostJson('/coupon/add', { data })

  const message = 'Ett fel uppstod vid skapandet, vänligen försök igen.'
  if (!res.ok) {
    throw new UserInputError(message)
  }
}
export const adminGetCoupons = async () => {
  const res = await request('/coupon/getValid')

  if (!res.ok) return []
  return (await res.json()).coupons
}
export const adminGetUsers = async (type = false) => {
  console.log('Getting users')

  const url = type ? '/admin/users?type=' + type : '/admin/users'
  const res = await requestGetJson(url)

  const message = 'Ett fel uppstod vid hämtandet, vänligen försök igen.'
  if (!res.ok) {
    throw new UserInputError(message)
  }
  return res.json()
}
export const adminGetOneUser = async userId => {
  const res = await requestGetJson(`/admin/user/${userId}`)
  if (!res.ok) throw 'Error getting user'
  const json = await res.json()
  return json
}
