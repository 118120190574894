import React from 'react'
import styled from 'styled-components'

import {
  getLocalStorage
} from '../util/dataHandling'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  
  @media screen and (max-width: 500px) {
    padding: 0 20px;
  }
`

const Text = styled.p`
  margin: 0.25em 0;
`

const SmallText = styled.p`
  font-size: ${props => props.big ? '20px' : '16px'};
  margin-top: ${props => props.bottom ? '12rem' : ''};

  @media screen and (max-width: 1200px) {
    margin-top: 16px;
  }
`

const TopRow = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 18px;
    margin-left: 10%;
    cursor: pointer;
  }
`

function TitleDesc ({ title, desc, popupText = '', click = null, extraText = '', bigExtraText = false, descVarName = '', descVarVal = '', smallTextBottom = false }) {
  const color = (getLocalStorage() && getLocalStorage().specialization) ? getLocalStorage().specialization.color : '009192'

  return (
    <Container className='titleDesc'>
      <TopRow>
        <h1>{title}</h1>
        {
          popupText ? <p onClick={click}>{popupText}</p> : ''
        }
      </TopRow>
      {desc ? (
        desc.split('\n').map(des => ( //key is chosen as first 4 letters in hope they will be unique
          <Text key={des.slice(0, 4)}>{des.replace(`{${descVarName}}`, descVarVal)}</Text>
        ))
      ) : (
        ''
      )}
      {extraText ? <SmallText big={bigExtraText} bottom={smallTextBottom}>{extraText}</SmallText> : ''}
    </Container>
  )
}

export default TitleDesc
