import StyledInputField from 'components/StyledInputField'
import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(1%, 700px) 1fr;
  box-sizing: border-box;
`
export const Question = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row: 1/2;
  grid-column: 2/3;
  .psychologistPageColors & {
    background-color: var(--turquoise);
    color: white;
  }
  background-color: var(--blue5);
  padding: 1.5rem;
  border-radius: 0.5rem;
`
export const Answer = styled.div`
  grid-row: 2/3;
  grid-column: 2/3;
`
export const Icon = styled.div`
  justify-self: end;
  align-self: flex-start;
  margin: 0.5rem;
  @media screen and (max-width: 500px) {
    display: none;
  }
`
export const AnswerIcon = styled(Icon)`
  grid-row: 2/3;
  grid-column: 1/2;
`
export const QuestionIcon = styled(Icon)`
  grid-column: 1/2;
  grid-row: 1/2;
`
export const TextField = styled(StyledInputField)`
  width: unset;
`
