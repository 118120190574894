
import styled from 'styled-components'

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 120px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TextContainer = styled.div`
  min-width: 300px;
  display: flex;
  position: relative;
  flex-direction: ${props => props.rowFlow ? 'row' : 'column'};
  justify-content: space-between;
  padding: 10px 0;
  margin: 10px 20px 10px 8px;
`

export const ReadMore = styled.div`
  transition: 200ms;
  color: #${props => props.selected ? 'fff' : '000'};
`

export const TextElement = styled.p`
  margin: 4px 0;
`

export const RebookLink = styled.button`
  width: 100px;
  transition: 200ms;
  background-color: var(--secondary);
  height: max-content;
  color: white;
  `
export const CancelLink = styled.button`
  width: 100px;
  background-color: var(--red);
`
export const JoinLink = styled.button`
  width: 100px;
`
export const TopText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
  }
`

export const DurationContainer = styled.div`
  transition: 200ms;
  color: #${props => props.selected ? 'fff' : '000'};
  margin-left: auto;
  font-size: 20px;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

export const TimeText = styled.div`
  transition: 200ms;
  color: ${props => props.selected ? '#fff' : '#000'};
  font-size: 40px;

  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
`