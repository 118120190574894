import AnsweringClientsPage from 'pages/AnsweringClientsPage'
import DocumentsPsychologist from 'pages/DocumentsPsychologist'
import ProfilePsychologistPage from 'pages/ProfilePsychologistPage'
import QuestionsOverviewPage from 'pages/QuestionsOverviewPage'
import { Route, Routes } from 'react-router-dom'

function PsychologistRoutes() {
  return (
    <Routes>
      <Route path='/dokument' element={<DocumentsPsychologist />} />
      <Route path='/profil' element={<ProfilePsychologistPage />} />
      <Route path='/fraga-psykologen' element={<QuestionsOverviewPage />} />
      <Route path='/fraga-psykologen/:questionId' element={<AnsweringClientsPage />} />
    </Routes>
  )
}

export default PsychologistRoutes
