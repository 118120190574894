import { adminDeleteUser, adminGetUsers } from 'api/apiAdmin'
import PsychologistFullInfo from 'components/PsychologistFullInfo'
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import '../css/fullscreen.css'
import { Psychologist } from './SearchForPsychologistPage/components'

const Page = styled.div`
  justify-content: flex-start;
`
const Content = styled.div`
  width: 100%;
  max-width: 600px;
`
const Container = styled.div`
  padding: 1.2rem;
  cursor: pointer;
  transition: 200ms;
  border-bottom: 1px solid black;
  &:nth-child(even) {
  }
  &:hover {
    background-color: lightgray;
  }
`
const UsersList = styled.div`
  width: 100%;
  overflow: auto;
`

const ClientPreview = ({ client, onClick }) => {
  return (
    <Container onClick={onClick}>
      <b>{client.fullName}</b>
    </Container>
  )
}
function AdminShowUsersPage() {
  const [clients, setClients] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    const fetch = async () => {
      const psychologists = await adminGetUsers()
      setClients(psychologists)
    }
    fetch()
  }, [])

  return (
    <Page className='page darkText noPaddingMobile'>
      <Content>
        <div className='flex itemsCenter'>
          <Routes>
            <Route path='klient' element={<h1>Klienter</h1>} />
            <Route path='psykolog' element={<h1>Psykologer</h1>} />
          </Routes>
        </div>
        <UsersList>
          <Routes>
            <Route
              path='klient'
              element={
                <>
                  {clients
                    .filter(user => user.userType === 'client')
                    .map(client => (
                      <ClientPreview
                        onClick={() => navigate(`klient/${client.id}`)}
                        key={client.id}
                        client={client}
                      />
                    ))}
                </>
              }></Route>
            <Route
              path='psykolog'
              element={
                <>
                  {clients
                    .filter(user => user.userType === 'psychologist')
                    .map(client => (
                      <PsychologistFullInfo
                        onClick={() => navigate(`psykolog/${client.id}`)}
                        key={client.id}
                        psychologist={client}
                      />
                    ))}
                </>
              }></Route>
          </Routes>
        </UsersList>
      </Content>
    </Page>
  )
}

export default AdminShowUsersPage
