import UserContext from 'context/UserContext'
import { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { queryAvailable } from '../../api/api'
import { getLocalStorage } from '../../util/dataHandling'
import { bookingMediumString, getDateString, getTimeString, titleCase } from '../../util/utils'
import Popup from '../Popup'
import { Buttons, CancelLink, Container, JoinLink, ReadMore, RebookLink, TextContainer, TextElement, TopText } from './components'

function ClientInfo({ booking }) {
  const clientText = !booking.client ? 'Ej bokad' : booking.client.firstName + ' ' + booking.client.lastName
  const clientPhone = !booking.client ? '' : booking.client.phoneNumber
  const helpType = booking.helpType || ''
  const helpOption = booking.helpOption || ''

  return (
    <>
      <TextElement>Kund: {clientText}</TextElement>
      <TextElement>Inriktning: {helpType}</TextElement>
      <TextElement>Område: {helpOption}</TextElement>
      {clientPhone ? <TextElement>Tele: {clientPhone}</TextElement> : ''}
    </>
  )
}

function BookingInfoClient({ filter, selected, booking, showClientInfo, className, showJoin, showRebook }) {
  const [user, setUser] = useContext(UserContext)
  const startDate = booking ? new Date(booking.startDate) : new Date()
  const endDate = booking ? new Date(booking.endDate) : new Date()
  const hasPsychologist = !!booking.psychologist
  let name
  if (hasPsychologist) {
    name = titleCase(booking.psychologist.fullName ? booking.psychologist.fullName : booking.psychologist.firstName + ' ' + booking.psychologist.lastName)
  } else {
    name = 'Kunde inte hämta information om psykologen.'
  }

  const location = useLocation()
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)

  const showReadMore = (e) => {
    e.stopPropagation()
    setVisible(true)
  }

  const rebookClick = async (e) => {
    e.stopPropagation()
    const bookings = await queryAvailable(new Date(), null, booking.psychologist._id)
    const mediumTitle = { 'video': 'Videolänk' }
    const userCopy = { ...user }
    await setUser({
      ...userCopy,
      selectedPsychologist: booking.psychologist,
      age: { selectedFollowUpOption: '', title: 'Vuxen' },
      medium: { selectedFollowUpOption: '', title: mediumTitle[booking.medium.selected], userDataTag: booking.medium.selected },
      filteredEvents: { bookings: bookings },
      helpOption: booking.helpOption,
      helpType: booking.helpType 
    })
    navigate('/stegTid')
  }

  return (
    <Container className={className}>
      <Popup visible={visible} onClose={() => setVisible(false)}><p>{booking.description ? booking.description : 'Det finns ingen beskrivning för denna bokning.'}</p></Popup>
        <TextContainer>
          <TextElement><b></b> {getTimeString(startDate, endDate)}</TextElement>
          <TopText>
            <TextElement><b></b> {getDateString(startDate)}</TextElement>
            {location.pathname === '/bokningar' && filter === 'Kommande' ? <ReadMore selected={selected} onClick={showReadMore}>Läs mer</ReadMore> : ''}
          </TopText>
          {showClientInfo ? <ClientInfo booking={booking} /> : <TextElement><b></b> {name}</TextElement>}
          <TextElement>{bookingMediumString(booking)}</TextElement>
          { booking.helpOption && <TextElement>{booking.helpType} - {booking.helpOption}</TextElement> }
        </TextContainer>
        <Buttons className='columnFlex'>
          { showJoin &&
            <JoinLink onClick={alert}><Link to='#'>Anslut</Link></JoinLink>
          }
          { showRebook &&
            <RebookLink selected={selected} onClick={rebookClick}><Link to='#'>Återboka</Link></RebookLink>
          }
          { showClientInfo && hasPsychologist &&
            <CancelLink onClick={alert}><Link to='#'>Avboka</Link></CancelLink>
          }
        </Buttons>
    </Container>
  )
}

export default BookingInfoClient
