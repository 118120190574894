import { DateTime, Interval } from 'luxon'
import { profileGet } from '../api/api'
import mediums from 'db/mediums.json'
import validator from 'validator'

export const getLoginRedirect = user => {
  let path = '/hem'
  if (user.userType === 'admin') {
    path = '/admin/anvandare/klient'
  } else if (user.userType === 'psychologist' && user.validated) {
    path = '/bokningar'
  } else if (user.userType === 'psychologist') {
    path = '/profil1'
  } else if (user.userType === 'client' && user.age && user.clientType) {
    path = '/hem'
  } else if (user.userType === 'client' && user.clientType) {
    path = '/hem'
  }
  return path
}
const getServerURL = () => {
  return process.env.REACT_APP_API_URL
}

export const checkIfSameDay = (date1, date2) => {
  if (!date1 || !date2) {
    return false
  }
  if (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  ) {
    return true
  }
  return false
}
const getImageURLFromName = name => {
  return process.env.REACT_APP_API_URL + '/images/profiles/' + name
}
export const categoryIconBaseURL = `${process.env.REACT_APP_API_URL}/public/helpIcons`

const getColorFromCategory = category => {
  let color
  switch (category) {
    case 'terapi':
      color = '#EAAFC8'
      break
    case 'företag':
      color = '#C2DFB7'
      break
    case 'skola':
      color = '#C8D3F9'
      break
    case 'ovetande':
      color = '#F9C6AC'
      break
    default:
      color = '#FFFFFF'
  }
  return color
}

const isNumericChar = c => {
  return /\d/.test(c)
}

const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export const toIntervalString = (startDate, endDate, format) => {
  return toInterval(startDate, endDate).toFormat(format)
}
export const toInterval = (startDate, endDate) => {
  const start = DateTime.fromISO(startDate)
  const end = DateTime.fromISO(endDate)
  const interval = Interval.fromDateTimes(start, end)
  return interval
}
const getDateString = date => {
  if (typeof date === 'string') {
    return DateTime.fromISO(date).toFormat('dd LLLL yyyy')
  } else {
    return DateTime.fromJSDate(date).toFormat('dd LLLL yyyy')
  }
}

const getTimeString = (startDate, endDate) => {
  return (
    DateTime.fromJSDate(startDate).toFormat('HH:mm') +
    ' - ' +
    DateTime.fromJSDate(endDate).toFormat('HH:mm')
  )
}

const getMonth = () => {
  const date = new Date()
  date.setDate(1)
  return date
}

const generateEmptyUserData = () => {
  return {
    loggedIn: false,
    userType: null,
    specialization: null,
    helpType: null,
    age: null,
    helpOption: null,
    medium: null,
    filteredEvents: { bookings: [] },
    selectedBooking: null,
    serverBooking: null,
    currentlyAddingBookings: [],
    shouldCancelOrder: false,
    selectedPsychologist: null,
    cookieExpiration: null
  }
}

const getLastChar = string => {
  return string.substr(string.length - 1)
}

export const bookingMediumString = booking => {
  let str = ''
  const medium = mediums.find(medium => medium.value === booking.medium.selected)
  if (!medium) return ''
  str = medium.label
  // if(booking.medium.selected === 'physical') str += ' ' + booking.city
  return str
}
const getLastCompletedURL = async userData => {
  let redirect = window.location.href

  if (redirect.includes('betalning') && !userData.serverBooking) {
    redirect = '/stegPsykolog'
  }

  if (redirect.includes('steg')) {
    if (
      redirect.includes('stegPsykolog') &&
      userData.filteredEvents &&
      !userData.filteredEvents.length
    ) {
      redirect = '/stegDatum'
    }

    if (redirect.includes('stegDatum') && !userData.medium) {
      redirect = '/stegMedium'
    }

    if (redirect.includes('stegMedium') && !userData.helpOption) {
      redirect = '/stegTerapi'
    }

    if (redirect.includes('stegTerapi') && !userData.helpType) {
      redirect = '/stegHjalptyp'
    }

    if (redirect.includes('stegHjalptyp') && !userData.age) {
      redirect = '/stegAlder'
    }

    if (redirect.includes('stegAlder') && !userData.specialization) {
      redirect = '/steg1'
    }
  }

  if (redirect.includes('profil')) {
    const profile = await profileGet()
    if (redirect.includes('profil6') && !profile.city) {
      redirect = '/profil5'
    }
    if (redirect.includes('profil5') && !profile.profileImg) {
      redirect = '/profil4'
    }
    if (
      redirect.includes('profil4') &&
      (!profile.description.specialization || !profile.description.self)
    ) {
      redirect = '/profil3'
    }
    if (redirect.includes('profil3') && !profile.specializations.length) {
      redirect = '/profil2'
    }
    if (
      redirect.includes('profil2') &&
      (!profile.fullName || !profile.phoneNumber || !profile.email || !profile.address)
    ) {
      redirect = '/profil1'
    }
  }

  return redirect
}

const arrayToString = array => {
  let string = ''
  array.forEach(property => {
    string = string + ', ' + property
  })
  return string.slice(2, string.length)
}

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const findCommon = (arr1, arr2) => {
  if (arr1?.length && arr2?.length) {
    return arr1.some(item => arr2.includes(item))
  }
  return false
}

const matchesFilters = (booking, filter) => {
  let mediumMatch = false
  const psychologist = booking.psychologist
  if (!filter.selectedMedium) {
    mediumMatch = true
  } else if (filter.selectedMedium.value === 'physical') {
    if (
      booking.medium.available.includes('physical') &&
      psychologist.city !== 'Inga fysiska möten'
    ) {
      mediumMatch = filter.selectedMedium.city === psychologist.city
    }
  } else {
    mediumMatch = booking.medium.available.includes(filter.selectedMedium.value)
  }
  const languageMatch = filter?.languages
    ? findCommon(filter.languages, psychologist.languages)
    : true
  //const specializationMatch = findCommon(filter.age[userData.age], element.psychologist.specializations)
  const helpOptionMatch = filter?.helpOption
    ? findCommon([filter.helpOption], psychologist.specializations)
    : true
  const ageMatch = filter.age ? psychologist.ages.includes(filter.age) : true
  return mediumMatch && helpOptionMatch && languageMatch && ageMatch
}

export const filterBookingsOnDay = (bookings, day) => {
  const filtered = bookings?.filter(booking => {
    const sameDay = DateTime.fromISO(booking.startDate)
      .startOf('day')
      .equals(DateTime.fromISO(day).startOf('day'))
    return sameDay
  })
  return filtered
}
const filterBookings = (bookings, user, remove = false) => {
  if (!Array.isArray(bookings)) throw 'Bookings was not an array'
  // grey out or remove non matching bookings!
  const newArr = []
  bookings.forEach(booking => {
    if (remove) {
      if (matchesFilters(booking, user)) {
        newArr.push(booking)
      }
    } else {
      booking.greyedOut = !matchesFilters(booking, user)
      newArr.push(booking)
    }
  })
  return newArr
}

export const validatePhone = str => {
  if (typeof str !== 'string') return false
  return validator.isMobilePhone(str, 'sv-SE')
}
export const validateEmail = str => {
  if (typeof str !== 'string') return false
  return validator.isEmail(str)
}

export const bookingsSorter = (bookingA, bookingB) => {
  return new Date(bookingA.startDate) - new Date(bookingB.startDate)
}
/**
 * Returns new array with given item toggled
 * @param {Array} array
 * @param item
 */
export const toggleInArray = (array, item) => {
  const included = array.includes(item)
  if (included) {
    return array.filter(element => element !== item)
  } else {
    return [...array, item]
  }
}
export const minutesUntil = dateStr => {
  return Math.ceil((new Date(dateStr).getTime() - Date.now()) / 60000)
}
const timeStrings = (start, stop, interval = 15) => {
  let [hours, minutes] = start.split('.').map(n => parseInt(n))
  const [endHour, endMinute] = stop.split('.').map(n => parseInt(n))
  const timeStamps = []

  for (let h = hours; h < endHour; h++) {
    for (let min = minutes; min < 60; min += interval) {
      let time = [h, min]
      time = time.map(t => t.toString().padStart(2, '0'))
      timeStamps.push({ text: time.join('.') })
    }
  }

  for (let min = 0; min <= endMinute; min += interval) {
    const time = [endHour, min]
    timeStamps.push({ text: time.map(t => t.toString().padStart(2, '0')).join('.') })
  }

  return timeStamps
}

export {
  getServerURL,
  getImageURLFromName,
  getColorFromCategory,
  isNumericChar,
  getDateString,
  getTimeString,
  getMonth,
  getLastChar,
  generateEmptyUserData,
  getLastCompletedURL,
  capitalize,
  arrayToString,
  filterBookings,
  titleCase,
  timeStrings
}
