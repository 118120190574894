import { requestPostJson, request } from './api'

export const queryPurchasedBookings = async (startDate, endDate, userState) => {
  const body = {}
  if (startDate) body.startDate = startDate.toISO()

  if (endDate) body.endDate = endDate.toISO()

  const res = await requestPostJson('/booking/queryOwn', body, {}, userState)
  if (!res.ok) throw 'Error getting bookings'

  return res.json()
}
export const fetchMyBookings = async userData => {
  const res = await requestPostJson('/booking/queryOwn', {})
  if (!res.ok) return false
  const bookings = await res.json()
  return bookings

  // if (userData.userType === 'client') {
  //   const bookingsObject = await queryPurchasedBookings()
  //   if (!bookingsObject) return false
  // } else if (userData.userType === 'psychologist') {
  //   const profile = await profileGet()
  //   bookingsObject.bookings = bookingsObject.bookings.map(booking => {
  //     booking.psychologist = profile
  //     return booking
  //   })
  // }
}
export const getOneBooking = async (bookingId, userState) => {
  const res = await requestPostJson(`/booking/queryOwn/${bookingId}`, {}, userState)
  if (!res.ok) return false
  return await res.json()
}

export const updateBookings = async bookings => {
  const body = {
    bookingData: bookings.map(booking => ({
      startDate: booking.startDate,
      endDate: booking.endDate,
      cost: booking.cost,
      medium: {
        available: booking.medium.available,
        selected: booking.medium.selected
      },
      dayBreakStart: booking.dayBreakStart,
      dayBreakEnd: booking.dayBreakEnd,
      dayStart: booking.dayStart,
      dayEnd: booking.dayEnd,
      _id: booking._id
    }))
  }

  const res = await request('/booking/update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  if (!res.ok) throw 'Error posting bookings'

  return await res.json()
}
export const bookingsCancel = async (bookingId, userState) => {
  const res = await requestPostJson('/booking/remove', { bookingId }, {}, userState)
  if (!res.ok) throw 'Could not remove booking'
  return true
}
export const bookingsAdd = async (booking, userState) => {
  const body = {
    startDate: booking.startDate.toISO(),
    duration: booking.duration,
    medium: { available: booking.availableMediums }
  }
  const res = await requestPostJson('/booking/add', body, {}, userState)
  if (!res.ok) throw await res.json()
  return await res.json()
}

// DEBUG ONLY
// finish order - same as would happen once you paid for it
export const orderFinish = async () => {
  console.log('finishing order')
  const res = await request('/order/finish')
  console.dir(res)

  try {
    console.log(await res.json())
  } catch {}
}
