import UserContext from 'context/UserContext'
import React, { useContext } from 'react'
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom'

function AuthRoute({ element, path, psychologist, admin }) {
    const userDataState = useContext(UserContext)
    const [user, setUser] = userDataState
    const location = useLocation()
    
    if (!user?.id) {
        return <Navigate to='/' replace state={{from: location}} />
    }
    const hasAccess = () => {
        const allowAllUsers = !admin && !psychologist
        return allowAllUsers || 
        (admin && user.userType==='admin' ) || 
        (psychologist && user.userType==='psychologist')
    }

    if(hasAccess()) return element
    return <h1>Nekad åtkomst</h1>
}

export default AuthRoute