import { PulseLoader } from 'react-spinners'

function Spinner({ size = 12}) {
  return (
    <div>
      <PulseLoader loading size={size} />
    </div>
  )
}

export default Spinner