import dataCouponsAdmin from 'db/couponsAdmin.json'
import dataDocumentAdmin from 'db/documentAdmin.json'
import AdminPsychologisDetails from 'pages/AdminPsychologistDetails'
import AdminShowUsersPage from 'pages/AdminShowUsersPage'
import AdminClientDetailsPage from 'pages/AdminClientDetailsPage'
import CouponsAdminPage from 'pages/CouponsAdminPage'
import DocumentsAdmin from 'pages/DocumentsPsychologist'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

function AdminRoutes() {
  return (
    <Routes>
      <Route path='/dokument' element={<DocumentsAdmin data={dataDocumentAdmin} />} />
      <Route path='/kuponger' element={<CouponsAdminPage data={dataCouponsAdmin} />} />
      <Route path='/anvandare/*' element={<AdminShowUsersPage />} />
      <Route path='/anvandare/klient/:id' element={<AdminClientDetailsPage />} />
      <Route path='/anvandare/psykolog/:id' element={<AdminPsychologisDetails />} />
    </Routes>
  )
}

export default AdminRoutes
