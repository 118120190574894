import UserContext from 'context/UserContext'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import PsychologistProfileGrid from './PsychologistProfileGrid'

function ProfilePsychologistPage() {
  const [user] = useContext(UserContext)

  return (
    <div className='page psychologistPageColors'>
      <div style={{ maxWidth: '650px' }}>
        <PsychologistProfileGrid user={user} />
        <Link to='/psykolog-profil1' className='fullWidth'>
          <button className='fullWidth'>Redigera</button>
        </Link>
        <br />
        <br />
        <br />
      </div>
    </div>
  )
}

export default ProfilePsychologistPage
