import { request, requestPostJson } from './api'

export const getMyQuestions = async () => {
  const res = await request('/question/my')
  if (!res.ok) throw 'Failed get questions'
  return res.json()
}
export const askQuestion = async (title, question, userState) => {
  const res = await requestPostJson('/question/ask', { question, title }, {}, userState)
  if (!res.ok) throw new Error('Failed asking question')
  return res.json()
}
export const answerQuestion = async (questionId, answer, links, userState) => {
  const res = await requestPostJson(
    `/question/answer/${questionId}`,
    { answer, links },
    {},
    userState
  )
  if (!res.ok) throw await res.json()
  return res.ok
}
export const getAllQuestions = async userState => {
  const res = await request('/question', {}, userState)
  if (!res.ok) throw 'Failed getting questions'
  return res.json()
}
export const getOneQuestion = async (questionId, userState) => {
  const res = await request(`/question/get/${questionId}`, {}, userState)
  if (!res.ok) throw 'Error getting one question'
  return res.json()
}
export const reserveQuestion = async (questionId, shouldReserve, userState) => {
  const res = await requestPostJson(
    `/question/reserve/${questionId}`,
    {
      reserve: shouldReserve
    },
    userState
  )
  if (!res.ok) throw 'Could not do reserve'
  return res.ok
}
export const payQuestionStripe = async (questionId, userState) => {
  const res = await request(`/question/pay/${questionId}`, {}, userState)
  if (!res.ok) throw 'Error question pay'
  const data = await res.json()
  if (!data.clientSecret) throw 'Data had no client secret'
  return data.clientSecret
}
export const payQuestionSwish = async (questionId, phoneNumber, userState) => {
  const res = await requestPostJson(
    `/question/swish/${questionId}`,
    { phoneNumber },
    {},
    userState
  )
  if (!res.ok) throw await res.json()
  return await res.json()
}
