import React from 'react'
import styled from 'styled-components'
import checked from 'img/check.svg'

const Option = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: ${props => props.selected ? '#000' : '#333'};
  cursor: pointer;
  transition: 200ms;

  :hover {
    transform: scale(1.02);
    color: #000;
  }
`
const TickBox = styled.div`
  border: 2px solid var(--secondary);
  border-radius: 20%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  ${props => props.selected && `background-image: url(${checked});`} 
  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

function RadioButton({ option = {}, onClick, selected,  }) {
  return (
      <Option selected={selected} onClick={() => onClick(option)}>
        <TickBox selected={selected} />
        <span>{option.label || option}</span>
      </Option>
  )
}

export default RadioButton