import React, { useContext, useState } from 'react'
import noProfilePicture from 'img/no-profile-image.svg'
import {
  Arrow,
  BookBar,
  CompetenceItem,
  Container,
  FavMark,
  IconContainer,
  Label,
  LangItem,
  MoreInfo,
  Name,
  ProfileImg,
  ProfileImgContainer
} from './components'
import { useNavigate } from 'react-router-dom'
import RadioButton from 'components/RadioButton'
import UserContext from 'context/UserContext'
import { togglePsychologistAsFavorite } from 'api/apiPsychologists'
import { useQueryClient } from 'react-query'
import { QueryKeys } from 'config'
import { profileGet } from 'api/api'
import { BsFillBookmarkFill } from 'react-icons/bs'
import UpdateProfilePopup from 'components/UpdateProfilePopup'

function PsychologistFullInfo({
  psychologist,
  hideBook,
  className,
  onClick,
  forceExpand,
  showLocation
}) {
  const [user, setUser] = useContext(UserContext)
  const [expanded, setExpanded] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  if (!psychologist) return null

  const img = psychologist.profileImg || noProfilePicture
  const onLabelClick = onClick || (() => setExpanded(prev => !prev))

  const isFavorite = user.favoritePsychologists.includes(psychologist.id)

  const toggleFavorite = async () => {
    await togglePsychologistAsFavorite(psychologist.id, !isFavorite)
    const profile = await profileGet()
    profile && setUser(profile)
  }

  const handleBook = () => {
    if (!user.email) {
      setIsOpen(true)
      return
    }
    navigate(`/stegMedium-vp/${psychologist.id}`)
  }

  return (
    <Container className={className}>
      <Label onClick={onLabelClick}>
        <ProfileImgContainer>
          <ProfileImg src={img} alt='Bild på psykologen' />
          {isFavorite && (
            <FavMark>
              <BsFillBookmarkFill size={15} style={{ color: 'var(--primary)' }} />
            </FavMark>
          )}
        </ProfileImgContainer>
        <div className='flexColumn pl'>
          <Name>
            {' '}
            {psychologist.firstName} {psychologist.lastName}
          </Name>
          <p className='mt-0'>{psychologist.field}</p>
        </div>
        <IconContainer>
          <Arrow
            size={20}
            style={{ transform: forceExpand || expanded ? 'rotate(180deg)' : '' }}
          />
        </IconContainer>
      </Label>
      <MoreInfo expanded={forceExpand || expanded}>
        {showLocation && <p>Mottagning: {psychologist.location}</p>}
        <p>{psychologist.description?.self}</p>
        <h4 className='mb-0'>Behandlingområden</h4>
        <div>
          {psychologist.specializations.map(lang => (
            <CompetenceItem key={lang}>{lang}</CompetenceItem>
          ))}
        </div>
        <h4 className='mb-0'>Språk</h4>
        <div>
          {psychologist.languages.map(lang => (
            <LangItem key={lang}>{lang}</LangItem>
          ))}
        </div>
        <div className='mt-1'>
          <RadioButton
            onClick={toggleFavorite}
            option={{ label: 'Bokmärkt' }}
            selected={user.favoritePsychologists.includes(psychologist.id)}
          />
        </div>
        {!hideBook && (
          <BookBar>
            <button onClick={handleBook}>Boka ett möte</button>
          </BookBar>
        )}
      </MoreInfo>
      <UpdateProfilePopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </Container>
  )
}

export default PsychologistFullInfo
