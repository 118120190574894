import React from 'react'
import styled from 'styled-components'
import { getDateString } from 'util/utils'

const Title = styled.h4`
  font-size: 1.12rem;
  margin-top: 4px;
`
const Spread = styled.div`
  font-size: 0.9em;
  /* border-top: 1px solid lightgray; */
`
const QuestionText = styled.p`
  margin-top: 0;
`
const QuestionContainer = styled.div`
  max-width: 700px;
  .psychologistPageColors & {
    background-color: var(--turquoise);
    color: white;
  }
  background-color: var(--blue5);
  border-radius: 1rem;
  padding: 1.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`
function QuestionPreview({ question, onClick, fullText, className, showAskwer }) {
  return (
    <QuestionContainer onClick={onClick} className={className}>
      <div className='spread'>
        <Title>{question.title}</Title>
        <Title></Title>
      </div>
      <QuestionText>
        {question.question.substring(0, fullText ? undefined : 200)}
      </QuestionText>
      {showAskwer && <span> {question.asker?.firstName}</span>}
      <Spread className='spread'>
        {showAskwer && <span> Skapad {getDateString(question.createdAt)}</span>}
        {question.answerer && (
          <span>
            Reserverad av: {question.answerer.firstName} {question.answerer.lastName}
          </span>
        )}
      </Spread>
    </QuestionContainer>
  )
}

export default QuestionPreview
