import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 60px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.value ? 'flex-start' : 'flex-end')};
  background-color: ${props => (props.value ? 'green' : 'red')};
  cursor: pointer;
`
const Knob = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: lightgray;
  box-shadow: 0 0 5px 0 black;
`

function ToggleSlider({ value, onChange }) {
  return (
    <Container value={value} onClick={() => onChange(!value)}>
      <Knob>{value ? 'Ja' : 'Nej'}</Knob>
    </Container>
  )
}

export default ToggleSlider
