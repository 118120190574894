import CustomAlert from 'components/CustomAlert/index.js'
import UserContext from 'context/UserContext.js'
import 'css/fontsImport.css'
import 'css/index.scss'
import 'css/mobile.css'
import 'css/stripe.css'
import React, { useCallback, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import AppRoutes from 'routes/AppRoutes.js'
import { logout, profileGet } from './api/api'
import Footer from './components/Footer.js'
import Header from './components/Header'
import { getLocalStorage, setLocalStorage } from './util/dataHandling'
import { getLastCompletedURL, getLoginRedirect } from './util/utils'
import { ReactQueryDevtools } from 'react-query/devtools'
import CookieConsent from 'components/CookieConsent/CookieConsent'

const queryClient = new QueryClient()

const browsingStartPage = () => {
  return (window.location.origin + '/' === window.location.href)
}

const NoAccess = () => {
  return (
    <div style={{ justifyContent: 'center', alignItems: 'center' }}>Du har tyvärr inte tillgång till denna sida.</div>
  )
}

function App() {
  const userState = useState(getLocalStorage())
  const [user, setUser] = userState
  const navigate = useNavigate()
  const location = useLocation()

  const fetchProfile = useCallback(async () => {
    if (user?.loggedIn) {
      const profile = await profileGet(userState)
      if (profile) {
        setUser((prev) => ({ ...prev, ...profile }))
      }
    }

    setLocalStorage(user)
  }, [setUser, user, userState])

  useEffect(() => {
    fetchProfile()

  }, [])

  useEffect(() => {
    //Whenever I set user data in the app I want localStorage to be in sync with that without calling it manully everywhere
    setLocalStorage(user)
  }, [user])

  useEffect(() => {
    const checkSessionStorage = async () => {
      if (user.loggedIn && ((user.cookieExpiration && new Date(user.cookieExpiration) < new Date()) || !user.cookieExpiration)) {
        await logout(userState)
        navigate('/')
      } else if (location.pathname === '/'  && user.loggedIn) {
        navigate(getLoginRedirect(user))
      }
    }
    checkSessionStorage()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const redirect = async () => {
    if ((!window.location.href.includes('login') && !window.location.href.includes('registrera')) && !user.loggedIn && !browsingStartPage()) {
      navigate('/')
    } else if (window.location.href.includes('steg') || window.location.href.includes('profil') || window.location.href.includes('betalning')) {
      const redirect = await getLastCompletedURL(user)
      if (redirect !== window.location.href) {
        navigate(redirect)
      }
    }
  }
  //redirect()

  // Saving bookings not yet appended to server
  return (
    <UserContext.Provider value={userState}>
      <QueryClientProvider client={queryClient}>

        {/* <link href='https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400&display=swap' rel='stylesheet' /> */}
        <Header />

        <AppRoutes />
        <CustomAlert />
        <Footer />
        <CookieConsent/>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </UserContext.Provider >
  )
}

export default App
