import React from 'react'
import styled from 'styled-components'
import noProfilePicture from 'img/no-profile-image.svg'

const Box = styled.div`
  display: inline-block;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
`
function ProfilePic({ src, size, className='' }) {
  const img = src || noProfilePicture

  return (
    <Box className={'profilePic '+className} style={{ 
      backgroundImage: `url(${img})`, 
      width: size, height: size,
      flexShrink: 0
      }}> 
    </Box>
  )
}

export default ProfilePic