import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Link, useNavigate, useLocation
} from 'react-router-dom'

import styled from 'styled-components'

import useWindowSize from 'hooks/useWindowSize'
import hamburgerIcon from 'img/bars-solid.svg'
import logo from 'img/goodmindlogo.svg'
import { logout } from 'api/api'
import StyledLink from '../StyledLink'
import HeaderAdmin from './LinksAdmin'
import LinksPsychologist from './LinksPsychologist'
import LinksClient from './LinksClient.js'
import UserContext from 'context/UserContext'
import { AiOutlineClose } from 'react-icons/ai'

const Container = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0.8rem;
  color: var(--darkText);
  background-color: white;
  transition: 200ms height;
  box-shadow: 0 0 10px 0px #AAA;
  font-size: 18px;
  @media screen and (max-width: 900px) {

  }
`


const HamburgerLinks = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
  min-height: 100vh;
  min-width: 250px;
  transform: translate(${props => props.expanded ? '0' : '100%'});
  display: flex;
  padding: 3rem 1rem 1rem;
  flex-direction: column;
  background-color: white;
  font-size: 1.2em;
  box-shadow: 0 0 5px 0 grey;

  @media screen and (min-width: 900px) {
    display: none;
  }
  
  transition: transform 0.5s;
  >*{
    margin-bottom: 0.8rem;
  }
`

const Hamburger = styled.img`
  width: 30px;
  cursor: pointer;
  @media screen and (min-width: 900px) {
    display: none;
  }
`

const AllLinks = styled.div`
  display: none;
  @media screen and (min-width: 900px) {
    display: flex;
    align-items: center;
  }
`

const Logo = styled.img`
  height: 48px;
  justify-self: flex-start;
  margin: 0 10px;
`

function Header() {
  const userState = useContext(UserContext)
  const [user, setUser] = userState
  const [expanded, setExpanded] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const isPsychologist = user.userType === 'psychologist'
  const isAdmin = user.userType === 'admin'
  const isClient = user.userType === 'client'

  const clearStorage = () => {
    localStorage.setItem('questionTitle', '')
    localStorage.setItem('questionText', '')
  }
  
  const logoutClick = async () => {
    clearStorage()
    await logout(userState)
  }

  useEffect(() => {
    setExpanded(false)
  }, [location])
  
  let logoLink
  if(isAdmin) {
    logoLink = '/admin/anvandare/klient'
  } else if(isPsychologist && user.validated) {
    logoLink = '/schemalaggning'
  } else if(isPsychologist){
    logoLink = 'profil1' 
  } else {
    logoLink = '/hem'
  }

  let links
  if (isAdmin) {
    links = <HeaderAdmin />
  } else if (isPsychologist) {
    links = <LinksPsychologist />
  } else {
    links = <LinksClient />
  }

  return (
    <Container menuExpanded={expanded}>
      <Link to={logoLink}><Logo src={logo} /></Link>
      <Hamburger onClick={() => setExpanded(prev => !prev)} src={hamburgerIcon} />

      <AllLinks>
        {user.id && <>
          {links}
          <StyledLink onClick={logoutClick}>Logga ut</StyledLink>
        </>}
      </AllLinks>

      {
        expanded && <HamburgerLinks expanded={expanded}>
          <AiOutlineClose className='clickable' style={{ alignSelf: 'flex-end' }} size={22} onClick={() => setExpanded(false)} />
          {user.id && <>
            {links}
            <StyledLink onClick={logoutClick}>Logga ut</StyledLink>
          </>}
        </HamburgerLinks>
      }
    </Container>
  )
}

export default Header
