"use strict";

var scrollDuration = 512;

function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

export const smoothScrollTo = (href, parent) => {
  var start = window.performance.now();
  var target = document.getElementById(href.substring(1));
  const parentElement = document.querySelector(parent);
  parentElement.style.position = "relative";
  if (!target) return;

  var startY = parentElement.scrollTop;
  console.log(startY);

  const deltaY = target.offsetTop - startY;
  console.log(deltaY);

  function move(pos) {
    parentElement.scroll(0, startY + deltaY * pos);
  }

  function update(ts) {
    var pos = (ts - start) / scrollDuration; // progression level

    if (pos >= 1) {
      return move(1);
    }

    move(easeInOutQuad(pos));

    window.requestAnimationFrame(update);
  }

  window.requestAnimationFrame(update);
};
