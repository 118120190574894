import DotsSpinner from 'components/DotsSpinner'
import UserContext from 'context/UserContext'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--turquoise);
  padding: 1.4rem;
  margin-left: 2rem;
  color: white;
`

function ReservedBar({ question, disableReserve, reserveClick }) {
  const [user, setUser] = useContext(UserContext)
  if (!question) return null
  const reservedByMe = question.answerer?.id === user.id

  return (
    <Container>
      {question.answerer ? (
        <>
          <div>
            <h3 className='mb-0'>Reserverad av:</h3>
            <h3 className='mt-0'>
              {question.answerer.firstName} {question.answerer.lastName}
            </h3>
          </div>
        </>
      ) : (
        <>
          <h3>Frågan är ej reserverad</h3>
        </>
      )}
      <p>En reserverad fråga kan bara besvaras av psykologen som reserverat</p>
      {(reservedByMe || !question.answerer) && (
        <button
          className='fullWidth'
          disabled={disableReserve}
          onClick={() => reserveClick(!question.answerer)}
          style={{ backgroundColor: 'var(--lightGreen)', color: 'var(--darkText)' }}>
          {disableReserve ? (
            <DotsSpinner />
          ) : (
            <span>{!question.answerer ? 'Reservera' : 'Avreservera'}</span>
          )}
        </button>
      )}
    </Container>
  )
}

export default ReservedBar
