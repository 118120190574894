import { useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { CopiedContainer, Coupon } from "./components"

function Copied() {
  return (
    <CopiedContainer>
      <p>Kopierad!</p>
    </CopiedContainer>
  )
}

const CouponItem = ({ coupon }) => {
  const [showCopyAlert, setShowCopyAlert] = useState(false)

  useEffect(() => {
    if (showCopyAlert) {
      setTimeout(() => setShowCopyAlert(false), 950)
    }
  }, [showCopyAlert])

  const copyAnimation = () => {
    setShowCopyAlert(true)
  }
  const unlimited = coupon.value === -1
  
  return (
    <CopyToClipboard text={coupon.code} className='clickable darkHover'>
      <Coupon onClick={copyAnimation}>
        {showCopyAlert ? <Copied /> : ''}
        <p className=' spread m-0'><span>{coupon.code}</span> <span>{unlimited ? 'Gratis besök' : coupon.code }</span></p>
      </Coupon>
    </CopyToClipboard>
  )
}

export default CouponItem