import React, { useEffect, useState } from 'react'
import SimpleScrollComponent from './SimpleScrollComponent'
import AdvancedScrollComponent from './AbvancedScrollComponent'
import ScheduleComponent from './ScheduleComponent'
import DocumentScrollComponent from './DocumentScrollComponent'
import { smoothScrollTo } from '../util/smoothScroll'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { getDateString } from '../util/utils'

const ScrollListComponent = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 65vh;
  overflow-y: auto;
  margin-top: auto;
  margin-bottom: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media screen and (max-width: 800px) {
    padding-bottom: 15px;
    align-items: center;
    margin-top: unset;
    margin-bottom: unset;
  }
  
`

let stackedAnimations = 0 // Ugly fix for not refreshing wrong state in the middle of animation but works

function ScrollList ({ selected, onClick, objects = [], type = null, 
  highlightColorOveride, showClientInfo = false, 
  onScrolled = () => {}, filter = '', selectedDates = null,
  setSelectedDates = null, psychologists = null,
  setUserData = null, onDeleteDayBookings = null,
  style
}) {
  let selectedDataTag
  if (selected) {
    selectedDataTag = selected.id ? selected.id : selected.title
  }
  const [, setIsMobile] = useState(false)
  const location = useLocation()
  useEffect(() => {
    const evalRefresh = () => {
      stackedAnimations = stackedAnimations - 1
      if (stackedAnimations < 0) {
        onScrolled()
      }
    }

    let res = []
    if (type === 'schedule') {
      for (const date of Object.keys(objects)) {
        res = [...res, objects[date].filter((object) => !!object.scrollTo)]
      }
    } else {
      res = objects.filter((object) => !!object.scrollTo)
    }
    if (res.length) {
      smoothScrollTo('#scrollTo', '.scrollList')
      stackedAnimations = stackedAnimations + 1
      window.setTimeout(evalRefresh, 512 + 512) // let it finish animating
    }

  }, [])

  useEffect(() => {
    if (window.innerWidth > 800) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    window.addEventListener('resize', (e) => {
      if (window.innerWidth > 800) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    })
  }, [location])


  const renderItem = (object, date = '') => {
    if (type === 'schedule') { // Check if schedule
      return (
        <ScheduleComponent date={date} selected={selectedDataTag === object.id} key={object.id} bookings={object} setSelectedDates={setSelectedDates} selectedDates={selectedDates} onDeleteDayBookings={onDeleteDayBookings} />
      )
    } else if (object.length) { // Check if on stegPsykolog
      return (
        <AdvancedScrollComponent filter={filter} colorOverride={highlightColorOveride} selected={selectedDataTag === object[0].id} onClick={() => onClick(object[0])} key={object[0].id} booking={object[0]} showClientInfo={showClientInfo} bookings={object} />
      )
    } else if (object.startDate) { // Check if option or booking
      return (
        <AdvancedScrollComponent filter={filter} colorOverride={highlightColorOveride} selected={selectedDataTag === object.id && filter !== "Tidigare"} onClick={() => onClick(object)} key={object.id} booking={object} showClientInfo={showClientInfo} psychologists={psychologists} setUserData={setUserData} />
      )
    } else if (object.displayName) {
      return (
        <DocumentScrollComponent document={object} selected={false} onClick={() => onClick()} key={object._id} />
      )
    } else {
      return (
        <SimpleScrollComponent colorOverride={highlightColorOveride} selected={selectedDataTag === object.title} onClick={() => onClick(object)} key={object.title} title={object.title} />
      )
    }
  }

  let items = []
  if (type === 'schedule') {
    for (const date of Object.keys(objects)) {
      items = [...items, renderItem(objects[date], date)]
    }
  } else if (location.pathname === '/stegPsykolog') {
    const tempObj = {}
    
    for (const booking of objects) {
      if (Object.keys(tempObj).includes(getDateString(new Date(booking.startDate)))) {
        tempObj[getDateString(new Date(booking.startDate))] = [...tempObj[getDateString(new Date(booking.startDate))], booking]
      } else {
        tempObj[getDateString(new Date(booking.startDate))] = [booking]
      }
    }
    items = Object.keys(tempObj) ? Object.keys(tempObj).map((key) => renderItem(tempObj[key])) : ''
  } else {
    items = objects ? objects.map((object) => renderItem(object)) : ''
  }

  return (
    <ScrollListComponent className='scrollList'>
      {items}
    </ScrollListComponent>
  )
}

export default ScrollList