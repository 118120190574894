import StyledLink from "components/StyledLink"
import React from "react"

const LinksClient = () => <>
  <StyledLink to='/hem'>Hem</StyledLink> 
  <StyledLink to='/fraga-psykologen-intro'>Fråga</StyledLink>
  <StyledLink to='/prislista'>Priser</StyledLink>
  <StyledLink to='/bokningar'>Bokningar</StyledLink>
  <StyledLink to='/profil'>Profil</StyledLink>
  <StyledLink to='/sok-psykolog'>Psykologer</StyledLink>
  <StyledLink to='/kuponger'>Betalning</StyledLink>
</>

export default LinksClient