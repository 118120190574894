import {
  adminDeleteUser,
  adminGetLicense,
  adminGetOneUser,
  adminValidatePsychologist
} from 'api/apiAdmin'
import Spinner from 'components/Spinner'
import ToggleSlider from 'components/ToggleSlider'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { userFeedback } from 'util/utilUi'

function AdminPsychologisDetails() {
  const queryClient = useQueryClient()
  const [licenseLink, setLicenseLink] = useState('')
  const {
    data: psychologist,
    isLoading,
    refetch
  } = useQuery('admin-psychologist', () => adminGetOneUser(id))
  const { mutate, isLoading: isValidating } = useMutation(
    ({ id, validity }) => adminValidatePsychologist(id, validity),
    { onSuccess: () => queryClient.refetchQueries('admin-psychologist') }
  )
  const navigate = useNavigate()
  const { id } = useParams()

  const toggleValidated = async () => {
    mutate({ id: psychologist.id, validity: !psychologist.validated })
    refetch()
  }
  const deleteUser = async () => {
    if (
      window.confirm(
        'Är du säker på att du vill ta bort användaren? Denna handling kan inte ångras.'
      )
    ) {
      const success = await adminDeleteUser(id)
      userFeedback(success, 'Användare borttagen', 'Kunde inte ta bort!')
      success && navigate('/admin/anvandare/psykolog')
    }
  }
  useEffect(() => {
    if (!psychologist) return
    adminGetLicense(psychologist.id)
      .then(setLicenseLink)
      .catch(() => {
        console.log('No license')
      })
  }, [psychologist])

  return (
    <div className='page darkText'>
      <div className='pageContent'>
        {!isLoading ? (
          <>
            <h1>{psychologist.fullName}</h1>
            <p>Email: {psychologist.email}</p>
            <p>Telefonnummer: {psychologist.phoneNumber}</p>
            <p>
              Språk:{' '}
              {psychologist.languages.map(lang => (
                <span key={lang}>{lang} </span>
              ))}{' '}
            </p>

            {licenseLink ? (
              <p>
                Licens:{' '}
                <a
                  href={licenseLink}
                  download
                  target='_blank'
                  className='underline clickable'>
                  Ladda ner
                </a>
              </p>
            ) : (
              <p>Ingen license har laddats upp</p>
            )}

            <div className='flex mt-2'>
              <button className='selfCenter' onClick={deleteUser}>
                Ta bort
              </button>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default AdminPsychologisDetails
