import ProfilePic from 'components/ProfilePic'
import { C1, C2, Column, Competence, Grid, Lang } from './components'

function PsychologistProfileGrid({ user }) {
  return (
    <Column>
      <h2 className='selfStart'>Profil</h2>
      <div className='spread fullWidth'>
        <div style={{ marginRight: '1rem' }}>
          <h2 className='mb-0'>{user.fullName}</h2>
          <p className='mt-0'>{user.field}</p>
        </div>
        <ProfilePic src={user.profileImg} />
      </div>
      <Grid>
        <C1>Email</C1> <C2>{user.email}</C2>
        <C1>Telefon</C1> <C2>{user.phoneNumber}</C2>
        <C1>Kompetenser </C1>{' '}
        <C2>
          {user.specializations.map(spec => (
            <Competence key={spec}>{spec}</Competence>
          ))}
        </C2>
        <C1>Språk</C1>{' '}
        <C2>
          {user.languages.map(lang => (
            <Lang key={lang} className='roundBox'>
              {lang}{' '}
            </Lang>
          ))}
        </C2>
        <C1>Validerad</C1> <C2>{user.validated ? 'Ja' : 'Nej'}</C2>
        <C1>Ålder klient</C1> <C2>{user.ages.join(', ')}</C2>
        <C1>Stad</C1> <C2>{user.city}</C2>
        <C1>Mottagning</C1> <C2>{user.location}</C2>
        <C1 className='selfStart mb-0'>Beskrivning</C1>
        <C2>{user.description.self}</C2>
        <C1 className='selfStart mb-0'>Kompetenser</C1>
        <C2>{user.description.specialization}</C2>
      </Grid>
      {/* <TextField readOnly maxLength={200} placeholder='Beskrivning' value={user.description.self} />
        <TextField readOnly maxLength={200} placeholder='Specialisering' value={user.description.specialization} /> */}
    </Column>
  )
}

export default PsychologistProfileGrid
