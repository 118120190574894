import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { getDateString, getTimeString, titleCase } from '../util/utils'
import Popup from '../components/Popup'

const TextContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px 0;
  margin: 10px 20px 10px 8px;
`

const ReadMore = styled.div`
  transition: 200ms;
  color: #${props => props.selected ? 'fff' : '000'};
`

const TextElement = styled.p`
  margin: 4px 0;
`

const RebookLink = styled.div`
  transition: 200ms;
  a {
    color: #${props => props.selected ? 'fff' : '000'};
    text-decoration: none;
  }

  @media screen and (max-width: 500px) {    
    a {
      font-size: 18px;
    }
  }
`

const TopText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
  }
`

function ClientInfo ({ booking }) {
  const clientText = !booking.client ? 'Ej bokad' : booking.client.firstName + ' ' + booking.client.lastName
  const clientPhone = !booking.client ? '' : booking.client.phoneNumber
  const helpType = booking.helpType || ''
  const helpOption = booking.helpOption || ''
  
  return (
    <>
      <TextElement>Klient: {clientText}</TextElement>
      <TextElement>Inriktning: {helpType}</TextElement>
      <TextElement>Område: {helpOption}</TextElement>
      {clientPhone ? <TextElement>Tele: {clientPhone}</TextElement> : ''}
    </>
  )
}

function AdvancedBookingInfo ({ filter, selected, booking, showClientInfo, bookings = [] }) {
  const startDate = bookings.length ? new Date(booking.startDate) : new Date(booking.startDate)
  const endDate = bookings.length ? new Date(bookings[bookings.length - 1].endDate) : new Date(booking.endDate)
  const name = booking.psychologist.fullName ? titleCase(booking.psychologist.fullName) : titleCase(booking.psychologist.firstName + ' ' + booking.psychologist.lastName)
  const location = useLocation()

  const [visible, setVisible] = useState(false)

  const showReadMore = (e) => {
    e.stopPropagation()
    setVisible(true)
  }

  return (
    <div>
      <Popup visible={visible} onClose={() => setVisible(false)}><p>{booking.description}</p></Popup>
      <TextContainer>
        <TopText>
          <TextElement><b>Datum:</b> {getDateString(startDate)}</TextElement>
          {location.pathname === '/bokningar' && booking.description && filter === 'Kommande' ? <ReadMore selected={selected} onClick={showReadMore}>Läs mer</ReadMore> : ''}
          {location.pathname === '/bokningar' && filter === 'Tidigare' && !showClientInfo ? <RebookLink selected={selected} onClick={showReadMore}><Link to='#'>Återboka</Link></RebookLink> : ''}
        </TopText>
        <TextElement>{bookings.length ? <b>Tider finns mellan:</b> : <b>Mötet är mellan:</b>} {getTimeString(startDate, endDate)}</TextElement>
        {showClientInfo ? <ClientInfo booking={booking} /> : <TextElement><b>Psykolog:</b> {name}</TextElement>}
      </TextContainer>
    </div>
  )
}

export default AdvancedBookingInfo
