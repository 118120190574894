import React from 'react'
import styled from 'styled-components'
import '../css/animations.css'
import wordsCount from 'words-count'

const Container = styled.div`
  width: 100%;
  position: relative;
`

const Input = styled.textarea`
  width: 100%;
  resize: none;
  font-family: Roboto, sans-serif;
  padding: 20px;
  font-size: 16px;
  border: 0;
  margin: 10px 0;
  outline: none;
  border-radius: 7px;
  background-color: #eee;
  min-height: ${props => props.minHeight + 'px'};
  resize: vertical;
`

const Counter = styled.span`
  margin: 0;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: calc(100% - 20px);
  /* min-height: 200px; */
  text-align: right;
  color: #999;
`

function StyledInputField({
  placeholder,
  value,
  redInput,
  onChange,
  showWordCount = false,
  upperWordCountLimit = 0,
  maxLength,
  readOnly,
  minHeight = 100,
  backgroundColor
}) {
  const style = {
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationName: redInput ? 'blinkRed' : '',
    backgroundColor
  }

  const counterText = upperWordCountLimit
    ? wordsCount(value) + ' av ' + upperWordCountLimit + ' ord'
    : wordsCount(value) + ' ord'

  return (
    <Container>
      <Input
        minHeight={minHeight}
        type='field'
        maxLength={maxLength}
        style={style}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        onChange={e => onChange(e.target.value)}
      />
      {showWordCount ? <Counter> {counterText} </Counter> : ''}
    </Container>
  )
}

export default StyledInputField
