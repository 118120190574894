import UserContext from 'context/UserContext'
import { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  background-color: ${props => props.bgColor || '#e8e8e8'};
  padding: 2rem;
  color: ${props => props.color};
`

const LinkBox = styled.div`
  margin: 0 1rem;
  color: var(--darkText);
`

function Footer() {
  const loc = useLocation()
  const [user, setUser] = useContext(UserContext)

  const notYetPsychPaths = ['/psykolog-profil'] // Link with psych style, but user is not psych
  const psychologistPaths = [
    '/psykolog-profil',
    '/psykolog/',
    '/fakturera',
    '/bokningar',
    '/schemalaggning'
  ]
  const isPsych = user.userType === 'psychologist'
  let psychologistPage =
    notYetPsychPaths.some(a => loc.pathname.includes(a)) ||
    (isPsych && psychologistPaths.some(a => loc.pathname.includes(a)))

  return (
    <Container bgColor={psychologistPage && 'var(--turquoise)'}>
      <LinkBox>
        <Link to='/hem'>
          <p style={{ color: psychologistPage && 'white' }}>GoodMind </p>
        </Link>
        {/* <Link to='/integritet'><p>© GoodMind 2021</p></Link>
        <Link to='/integritet'><p>@copyright 556984-1876</p></Link> */}
      </LinkBox>
      <LinkBox>
        <Link to='/anvandarvillkor'>
          <p style={{ color: psychologistPage && 'white' }}>Användarvillkor</p>
        </Link>
        <Link to='/integritet'>
          <p style={{ color: psychologistPage && 'white' }}>Integritetspolicy</p>
        </Link>
      </LinkBox>
    </Container>
  )
}

export default Footer
